import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { ValidationResult } from '../helpers/codeValidation';

export type CapacityType = {
	code: string;
	name: string;
};

export type LookupCapacityTypesResponse = {
	keyword: string;
	options: CapacityType[];
};

class CapacityTypesAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static async getCapacityTypes(keyword: string) {
		return CapacityTypesAPI.get<LookupCapacityTypesResponse>(
			`/capacity-types/lookup?Keyword=${keyword}`
		);
	}
	static async validateCapacityCodes(codes: string[]) {
		const params = new URLSearchParams({
			codes: codes.join(','),
		});

		return CapacityTypesAPI.get<ValidationResult<CapacityType>>(
			`/capacity-types/validate?${params.toString()}`
		);
	}
}

export default CapacityTypesAPI;
