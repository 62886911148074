import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';
import z from 'zod';

const manufacturerSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Manufacturers are required')
);

export const webToolManufacturerGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	manufacturers: manufacturerSchema,
});

export const createWebToolManufacturerGroupSchema =
	webToolManufacturerGroupSchema.pick({
		name: true,
		description: true,
		manufacturers: true,
	});

export const updateWebToolManufacturerGroupSchema =
	webToolManufacturerGroupSchema.pick({
		name: true,
		description: true,
		manufacturers: true,
	});

export type CreateWebToolManufacturerGroupModel = z.infer<
	typeof createWebToolManufacturerGroupSchema
>;

export type UpdateWebToolManufacturerGroupModel = z.infer<
	typeof updateWebToolManufacturerGroupSchema
>;
