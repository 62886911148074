import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';
import z from 'zod';

const aircraftModelsSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Aircraft Models are required')
);

export const webToolAircraftModelGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	aircraftModels: aircraftModelsSchema,
});

export const createWebToolAircraftModelGroupSchema =
	webToolAircraftModelGroupSchema.pick({
		name: true,
		description: true,
		aircraftModels: true,
	});

export const updateWebToolAircraftModelGroupSchema =
	webToolAircraftModelGroupSchema.pick({
		name: true,
		description: true,
		aircraftModels: true,
	});

export type CreateWebToolAircraftModelGroupModel = z.infer<
	typeof createWebToolAircraftModelGroupSchema
>;

export type UpdateWebToolAircraftModelGroupModel = z.infer<
	typeof updateWebToolAircraftModelGroupSchema
>;
