import { useMemo } from 'react';
import { LoaderFunctionArgs, Navigate, useOutlet } from 'react-router-dom';
import Tabs, { Tab } from 'ui/components/Tabs/Tabs';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import useRootLoader from 'utils/hooks/useRootLoader';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import { rootLoader } from '../../main';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	return null;
};

export function WebToolGroupPage() {
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;
	const outlet = useOutlet();

	const station: Tab = {
		name: 'Airports',
		to: '/webtool/groups/station',
		end: false,
	};

	const airlines: Tab = {
		name: 'Airlines',
		to: '/webtool/groups/airline',
		end: false,
	};

	const freightForwarders: Tab = {
		name: 'Freight Forwarders',
		to: '/webtool/groups/freight',
		end: false,
	};

	const weightBreaks: Tab = {
		name: 'Weight Breaks',
		to: '/webtool/groups/breaks',
		end: false,
	};

	const aircraftModels: Tab = {
		name: 'Aircraft Models',
		to: '/webtool/groups/aircraft',
		end: false,
	};

	const manufacturers: Tab = {
		name: 'Manufacturers',
		to: '/webtool/groups/manufacturer',
		end: false,
	};

	const tabs = useMemo(() => {
		const sources = rootLoaderData?.userProfile.sources;
		const webToolAirlineCount =
			rootLoaderData?.userProfile.webToolAirlineCount ?? 0;
		if (!sources) return [];

		const hasAwb = sources.totalAirWaybill > 0;
		const hasCapacity = sources.totalCapacity > 0;

		return [
			hasAwb && weightBreaks,
			((hasAwb && sources.totalCass > 0) || hasCapacity) && station,
			((hasAwb && webToolAirlineCount !== 1) || hasCapacity) && airlines,
			hasCapacity && aircraftModels,
			hasCapacity && manufacturers,
			{
				name: 'Cities',
				to: '/webtool/groups/city',
				end: false,
			},
			hasAwb && freightForwarders,
			{
				name: 'Trade Lanes',
				to: '/webtool/groups/trade',
				end: false,
			},
			{
				name: 'Geography',
				to: '/webtool/groups/geography',
				end: false,
			},
		].filter((tab: Tab | false): tab is Tab => tab !== false);
	}, [rootLoaderData]);

	if (tabs.length === 0) {
		return <Navigate to="/" />;
	} else if (!outlet) {
		return <Navigate to={tabs[0].to} />;
	}

	return (
		<>
			{tabs.length > 1 && (
				<Tabs className="page-tabs" tabs={tabs} renderOutlet={false} />
			)}
			{outlet}
		</>
	);
}

export const WEBTOOL_GROUP_AREA_ROUTE: DecentralizedRouteProps = {
	element: <WebToolGroupPage />,
};
