import { format } from 'date-fns';
import WorksheetScheduleModal, {
	WorksheetScheduleModalMode,
} from 'iata-cargois-client-portal/src/components/WorksheetScheduleModal/WorksheetScheduleModal';
import { rootLoader } from 'iata-cargois-client-portal/src/main';
import { useWebToolContext } from 'iata-cargois-client-portal/src/routes/web-tool/WebToolContext';
import { Package, Plane } from 'lucide-react';
import { useMemo } from 'react';
import Badge from 'ui/components/Badge/Badge';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import PageHeader from 'ui/components/PageHeader/PageHeader';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import useRootLoader from 'utils/hooks/useRootLoader';
import { LoaderData } from 'utils/types/loaderData';
import { useWorksheetContext } from './WorksheetContext';
import WorksheetToolbar from './WorksheetToolbar';

type WorksheetHeaderProps = {};

const WorksheetHeader = ({}: WorksheetHeaderProps) => {
	const {
		worksheet,
		isScheduleModalOpen,
		setIsScheduleModalOpen,
		schedule,
		setSchedule,
	} = useWorksheetContext();
	const { worksheets, loadWorksheets } = useWebToolContext();

	/**
	 * TODO: This is a bit of a trick to gain reactivity from the rename
	 * functionality. We'll eventually have to find a better way to do this.
	 */
	const webToolWorksheet = useMemo(
		() => worksheets.find((w) => w.id === worksheet.id),
		[worksheet, worksheets]
	);

	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;
	const reportCounts =
		rootLoaderData?.userProfile.reportCounts.totalWebTool ?? 0;

	const headerLabel = useMemo(() => {
		const labelItems: string[] = [];

		if (reportCounts > 1) {
			labelItems.push(worksheet.subscriptionName);
		}

		labelItems.push(
			`Created on ${format(worksheet.createdAt, "dd MMM yyyy 'at' HH:mm")}`
		);

		return labelItems.join(' • ');
	}, [worksheet]);

	const Icon = worksheet.reportDataType === 'capacity' ? Plane : Package;

	return (
		<PageHeader
			title={
				<Flex alignItems="center" gap={12}>
					<Icon size={20} />
					<span>{webToolWorksheet?.name}</span>

					{worksheet.shareDetails && (
						<Tooltip>
							<Tooltip.Trigger>
								<Badge>
									Shared
									{worksheet.shareDetails.isModified ? ' - modified' : ''}
								</Badge>
							</Tooltip.Trigger>
							<Tooltip.Content>
								Shared by {worksheet.shareDetails.name} on{' '}
								<DateFragment date={worksheet.createdAt} includeTime />
							</Tooltip.Content>
						</Tooltip>
					)}
					{worksheet.supportWorksheet && <Badge>Support</Badge>}
				</Flex>
			}
			label={headerLabel}
		>
			<WorksheetToolbar />

			<WorksheetScheduleModal
				mode={
					schedule
						? WorksheetScheduleModalMode.EDIT
						: WorksheetScheduleModalMode.CREATE
				}
				isOpen={isScheduleModalOpen}
				onClose={() => setIsScheduleModalOpen(false)}
				initialSchedule={schedule}
				worksheetId={worksheet.id}
				onAfterSave={(schedule) => {
					setSchedule(schedule);
					loadWorksheets();
					setIsScheduleModalOpen(false);
				}}
				onAfterDelete={() => {
					setSchedule(undefined);
					loadWorksheets();
					setIsScheduleModalOpen(false);
				}}
			/>
		</PageHeader>
	);
};

export default WorksheetHeader;
