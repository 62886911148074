import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import DateFragment from 'ui/components/DateFragment';
import { WebToolManufacturerGroupItem } from 'utils/api/WebToolGroupAPI';

const columnHelper = createColumnHelper<WebToolManufacturerGroupItem>();

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
	enableSorting: false,
});

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => (
		<Link to={`/webtool/groups/manufacturer/${info.row.original.id}`}>
			{info.getValue()}
		</Link>
	),
});

export const modelsColumn = columnHelper.accessor('manufacturers', {
	header: 'Manufacturers',
	cell: (info) =>
		info
			.getValue()
			.map((model) => model.name)
			.join(', '),
});

export const descriptionColumn = columnHelper.accessor('description', {
	header: 'Description',
});

export const conflictsColumn = columnHelper.display({
	id: 'conflicts',
	header: 'Manufacturer Overlaps',
	cell: (item) =>
		item.row.original.conflicts.map((conf, i) => (
			<>
				{i !== 0 ? ', ' : ''}
				<span> {conf.name} </span>
			</>
		)),
});
