import { MouseEvent } from 'react';
import Alert from 'ui/components/Alert/Alert';
import { WorksheetBase } from 'utils/api/WebToolAPI';
import WorksheetBrowserItem from './WorksheetBrowserItem';

type WorksheetBrowserListProps = {
	worksheets: WorksheetBase[];
	onWorksheetClick?: (worksheet: WorksheetBase, e: MouseEvent) => void;
	onWorksheetScheduleClick?: (worksheet: WorksheetBase, e: MouseEvent) => void;
};

const WorksheetBrowserList = ({
	worksheets,
	onWorksheetClick,
	onWorksheetScheduleClick,
}: WorksheetBrowserListProps) => {
	if (worksheets.length === 0) {
		return (
			<Alert intent="info" title="No worksheets found">
				There are no worksheets matching your search.
			</Alert>
		);
	}

	const groupedWorksheets = worksheets.reduce(
		(acc, worksheet) => {
			// Group by subscription name
			const subscriptionName = worksheet.subscriptionName;

			if (!acc[subscriptionName]) {
				acc[subscriptionName] = [];
			}

			acc[subscriptionName].push(worksheet);
			return acc;
		},
		{} as Record<string, WorksheetBase[]>
	);

	return (
		<div className="worksheet-browser__subscriptions">
			{Object.entries(groupedWorksheets).map(
				([subscriptionName, worksheets]) => (
					<>
						<b>{subscriptionName}</b>

						<ul className="worksheet-browser__list">
							{worksheets.map((worksheet) => (
								<WorksheetBrowserItem
									key={worksheet.id}
									worksheet={worksheet}
									onClick={(e) => {
										onWorksheetClick?.(worksheet, e);
									}}
									onScheduleClick={(e) => {
										onWorksheetScheduleClick?.(worksheet, e);
									}}
								/>
							))}
						</ul>
					</>
				)
			)}
		</div>
	);
};

export default WorksheetBrowserList;
