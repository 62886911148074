import { Trash2, X } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import ManufacturerPicker from 'ui/components/ManufacturerPicker/ManufacturerPicker';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import WebToolGroupAPI from 'utils/api/WebToolGroupAPI';
import { formDataAsObject } from 'utils/formData/formData';
import { createToast } from 'utils/helpers/toast';
import { updateWebToolManufacturerGroupSchema } from 'utils/schemas/webToolManufacturerGroupSchema';
import { DecentralizedRouteProps } from 'utils/types/common';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';
import DeleteWebToolGroupModal from '../../components/DeleteWebToolGroup/DeleteWebToolGroup';

const loader = async ({ params }: LoaderFunctionArgs) => {
	const webToolAvailable = await UserAPI.hasWebtoolAccess();
	if (!webToolAvailable) {
		return redirect('/');
	}

	const groupItem = await WebToolGroupAPI.getManufacturerGroup(
		params.id as string
	);

	return {
		group: groupItem,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		const id = params.id as string;

		await WebToolGroupAPI.deleteManufacturerGroup(id);

		createToast(ToastType.SUCCESS, 'Group deleted successfully');

		return redirect(`/webtool/groups/manufacturer`);
	}

	if (request.method === 'POST') {
		const id = params.id as string;
		const formData = await request.formData();
		const updateModel = updateWebToolManufacturerGroupSchema.parse(
			formDataAsObject(formData)
		);

		const data = await WebToolGroupAPI.updateManufacturerGroup(id, updateModel);

		if ('id' in data) {
			createToast(ToastType.SUCCESS, 'Group saved successfully');

			return redirect(`/webtool/groups/manufacturer`);
		}

		return data;
	}
	return null;
};

const WebToolGroupEdit = () => {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const actionData = useActionData() as ActionData<typeof action>;
	const data = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();

	return (
		<div className="content">
			<div>
				<ValidatedForm
					method="post"
					validator={updateWebToolManufacturerGroupSchema}
				>
					<Grid>
						<PageHeader title="Edit Manufacturer Group">
							<Button
								variant="secondary"
								to={`/webtool/groups/manufacturer`}
								icon={X}
							>
								Cancel
							</Button>
							<Button
								isDisabled={navigation.state !== 'idle'}
								type="button"
								variant="secondary"
								icon={Trash2}
								intent="danger"
								onClick={() => setDeleteModalOpen(true)}
								data-cy="userDetailsDeleteButton"
							>
								Delete
							</Button>
							<Button
								isLoading={navigation.state !== 'idle' && !!navigation.formData}
								loadingText="Updating Group"
								type="submit"
							>
								Update Group
							</Button>
						</PageHeader>

						<Grid>
							<ErrorList error={actionData} />
							<Grid columns={2} isBox>
								<Grid>
									<TextField
										name="name"
										label="Name"
										placeholder="Enter the group name"
										defaultValue={data.group.name}
										isRequired
										autoFocus
									/>
									<TextField
										name="description"
										label="Description"
										placeholder="Enter the group description"
										defaultValue={data.group.description}
										isRequired
										autoFocus
									/>
								</Grid>
								<Grid>
									<ManufacturerPicker
										name="manufacturers"
										label="Manufacturers"
										isRequired
										isMulti
										initialValues={data.group.manufacturers}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</ValidatedForm>
				<DeleteWebToolGroupModal
					action={`/webtool/groups/manufacturer/${data.group.id}`}
					webToolGroup={data.group}
					isOpen={isDeleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
				/>
			</div>
		</div>
	);
};

export const WEBTOOL_MANUFACTURER_GROUP_EDIT_ROUTE: DecentralizedRouteProps = {
	loader,
	action: action,
	element: <WebToolGroupEdit />,
};
