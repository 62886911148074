import {
	zArrayOfStringRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';
import z from 'zod';

const airlinesSchema = z.preprocess(
	zEnsureArrayPreprocessor,
	zArrayOfStringRequired('Airlines is required')
);

export const webToolAirlineGroupSchema = z.object({
	id: z.string(),
	name: z
		.string({})
		.min(1, { message: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	description: z
		.string({})
		.min(1, { message: 'Description is required' })
		.max(250, { message: 'Description must not be more than 250 characters' }),
	airlines: airlinesSchema,
});

export const createWebToolAirlineGroupSchema = webToolAirlineGroupSchema.pick({
	name: true,
	description: true,
	airlines: true,
});

export const updateWebToolAirlineGroupSchema = webToolAirlineGroupSchema.pick({
	name: true,
	description: true,
	airlines: true,
});

export type CreateWebToolAirlineGroupModel = z.infer<
	typeof createWebToolAirlineGroupSchema
>;
export type UpdateWebToolAirlineGroupModel = z.infer<
	typeof updateWebToolAirlineGroupSchema
>;
