import { useDroppable } from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortDirection } from '@tanstack/react-table';
import classNames from 'classnames';
import { ArrowLeftRight, RotateCcw } from 'lucide-react';
import { useMemo } from 'react';
import { sortBySortOrderArray } from 'utils/helpers/sorting';
import { LayoutBuilderSortable } from './LayoutBuilder';
import LayoutBuilderItem from './LayoutBuilderItem';

type LayoutBuilderSectionProps = {
	label: string;
	description?: string;
	items: LayoutBuilderSortable[];
	sortOrder: string[];
	sortableId: string;
	onReset?: () => void;
	showResetWhenEmpty?: boolean;
	onSwitch?: () => void;
	emptyText?: string;

	isDisabled?: boolean;
	isEmphasized?: boolean;

	sortDirections?: Record<string, SortDirection>;
	onSortDirectionChange?: (id: string, sortDirection: SortDirection) => void;

	onRemove?: (id: string) => void;
	onAddToSort?: (id: string) => void;
	onRemoveFromSort?: (id: string) => void;
	isSorted?: (id: string) => boolean;

	prefixItemIds?: boolean;
};

const LayoutBuilderSection = ({
	label,
	description,
	items,
	sortableId,
	sortOrder,
	onReset,
	showResetWhenEmpty = false,
	onSwitch,
	emptyText = 'No items',
	sortDirections,
	onSortDirectionChange,
	isEmphasized,
	isDisabled,
	onRemove,
	onAddToSort,
	onRemoveFromSort,
	isSorted,
	prefixItemIds = false,
}: LayoutBuilderSectionProps) => {
	const { setNodeRef } = useDroppable({
		id: sortableId,
		data: {
			type: 'container',
			children: items,
		},
	});

	const sortedItems = useMemo(() => {
		return [...items].sort(sortBySortOrderArray(sortOrder, 'id'));
	}, [items, sortOrder]);

	const canSort = sortDirections && onSortDirectionChange;

	const handleSortDirectionChange = (
		id: string,
		sortDirection: SortDirection
	) => {
		if (!canSort) return;

		onSortDirectionChange(id, sortDirection);
	};

	const namespacedItems = useMemo(() => {
		if (!prefixItemIds) return sortedItems;

		return sortedItems.map((item) => ({
			...item,
			id: `${sortableId}.${item.id}`,
		}));
	}, [sortableId, sortedItems, prefixItemIds]);

	return (
		<SortableContext
			items={namespacedItems}
			strategy={verticalListSortingStrategy}
			id={sortableId}
			disabled={isDisabled}
		>
			<div
				className={classNames(
					'layout-builder__section',
					isDisabled && 'layout-builder__section--disabled',
					isEmphasized && 'layout-builder__section--emphasized'
				)}
			>
				<div className="layout-builder__section-header">
					<b className="layout-builder__section-name">{label}</b>
					<div className="layout-builder__section-actions">
						{onReset && (sortedItems.length > 0 || showResetWhenEmpty) && (
							<button
								className="layout-builder__section-action layout-builder__section-action--destructive"
								type="button"
								onClick={onReset}
							>
								<RotateCcw size={14} />
								Reset
							</button>
						)}
						{onSwitch && (
							<button
								className="layout-builder__section-action"
								type="button"
								onClick={onSwitch}
							>
								<ArrowLeftRight size={14} />
								Switch
							</button>
						)}
					</div>
				</div>
				<ul
					className={classNames(
						'layout-builder__list'
						// isDroppable && isOver && 'layout-builder__list--drop-over'
					)}
					ref={!isDisabled ? setNodeRef : undefined}
				>
					{items.length === 0 && (
						<li
							className="layout-builder__item layout-item layout-item--empty"
							role="presentation"
						>
							{emptyText}
						</li>
					)}
					{sortedItems.map((item) => (
						<LayoutBuilderItem
							id={prefixItemIds ? `${sortableId}.${item.id}` : item.id}
							key={item.id}
							item={item}
							sortDirection={
								canSort ? (sortDirections[item.id] ?? 'asc') : undefined
							}
							onSortDirectionChange={(newDirection) => {
								handleSortDirectionChange(item.id, newDirection);
							}}
							isDisabled={isDisabled}
							onRemove={onRemove ? () => onRemove(item.id) : undefined}
							onAddToSort={onAddToSort ? () => onAddToSort(item.id) : undefined}
							onRemoveFromSort={
								onRemoveFromSort ? () => onRemoveFromSort(item.id) : undefined
							}
							isSorted={isSorted ? isSorted(item.id) : undefined}
						/>
					))}
					{description && (
						<li
							className="layout-builder__description"
							role="presentation"
							dangerouslySetInnerHTML={{
								__html: description.replaceAll('\n', '<br>'),
							}}
						></li>
					)}
				</ul>
			</div>
		</SortableContext>
	);
};

export default LayoutBuilderSection;
