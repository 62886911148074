import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';

export type GetUserProfileResponse = {
	reportCounts: {
		totalAdhoc: number;
		totalBip: number;
		totalWebTool: number;
		totalStatic: number;
	};
	sources: {
		totalCass: number;
		totalCdd: number;
		totalNdd: number;
		totalAirWaybill: number;
		totalCapacity: number;
	};
	cddContributorEnabled: boolean;
	nddContributorEnabled: boolean;
	webToolAirlineCount: number | null;
	cddEnabled: boolean;
};

class UserAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getUserProfile() {
		return UserAPI.get<GetUserProfileResponse>(`/users/profile`);
	}

	static async hasWebtoolAccess() {
		try {
			const profile = await UserAPI.getUserProfile();
			return profile.reportCounts.totalWebTool > 0;
		} catch (error) {
			return false;
		}
	}
}

export default UserAPI;
