import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { ValidationResult } from '../helpers/codeValidation';

export type Manufacturer = {
	code: string;
	name: string;
	itemType?: 'manufacturer';
};

export type LookupManufacturersResponse = {
	keyword: string;
	options: Manufacturer[];
	groups: null;
};

class ManufacturersAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static async getManufacturers(keyword: string) {
		return ManufacturersAPI.get<LookupManufacturersResponse>(
			`/manufacturers/lookup?Keyword=${keyword}`
		);
	}

	static async validateManufacturerCodes(codes: string[]) {
		const params = new URLSearchParams({
			codes: codes.join(','),
		});

		return ManufacturersAPI.get<ValidationResult<Manufacturer>>(
			`/manufacturers/validate?${params.toString()}`
		);
	}
}

export default ManufacturersAPI;
