import { Package, Plane } from 'lucide-react';
import { FormEvent, useId, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'ui/components/Button/Button';
import { WorksheetDataType } from 'utils/api/WebToolAPI';
import { catchWithError } from 'utils/helpers/catchHandlers';
import CreateWorksheetForm from '../../components/CreateWorksheetModal/CreateWorksheetForm';
import WebToolWorksheetLoader from '../../components/WebToolWorksheetLoader/WebToolWorksheetLoader';
import { useWebToolContext } from './WebToolContext';

type WebToolEmptyStateProps = {
	onWorksheetBrowserOpen: () => void;
};

const WebToolEmptyState = ({
	onWorksheetBrowserOpen,
}: WebToolEmptyStateProps) => {
	const { worksheets, subscriptions, isLoadingWorksheets, createWorksheet } =
		useWebToolContext();

	const createMawbFormId = useId();
	const createCapacityFormId = useId();

	const [isLoading, setLoading] = useState<WorksheetDataType | false>(false);

	const mostRecentWorksheets = useMemo(() => {
		return [...worksheets]
			.sort((a, b) => {
				return (
					(b.updatedAt ?? b.createdAt).getTime() -
					(a.updatedAt ?? a.createdAt).getTime()
				);
			})
			.slice(0, 5);
	}, [worksheets]);

	const handleCreateWorksheet = async (
		e: FormEvent,
		type: WorksheetDataType
	) => {
		e.preventDefault();
		setLoading(type);

		const form = e.target as HTMLFormElement;
		const formData = new FormData(form);

		const name = formData.get('name') as string;
		const subscriptionId = formData.get('subscriptionId') as string;

		await createWorksheet(name, subscriptionId);
		setLoading(false);
	};

	if (isLoadingWorksheets) {
		return <WebToolWorksheetLoader />;
	}

	const [awbSubscriptions, capacitySubscriptions] = subscriptions.reduce(
		(acc, cur) => {
			acc[cur.reportDataType === 'awb' ? 0 : 1].push(cur);
			return acc;
		},
		[[], []] as [typeof subscriptions, typeof subscriptions]
	);

	return (
		<div className="webtool__empty empty">
			<div className="empty__column">
				<h2 className="empty__title">
					<Package size={24} className="empty__icon" />
					Create Demand report
				</h2>

				<p>
					Create a report analysing market trends, research, and customer &
					supplier behaviour.
				</p>

				<div className="empty__create">
					<CreateWorksheetForm
						subscriptions={awbSubscriptions}
						id={createMawbFormId}
						onSubmit={(e) =>
							handleCreateWorksheet(e, 'awb').catch(catchWithError)
						}
					/>
					<Button
						variant="secondary"
						type="submit"
						form={createMawbFormId}
						isLoading={isLoading === 'awb'}
						isDisabled={!!isLoading}
					>
						Create report
					</Button>
				</div>
			</div>
			{capacitySubscriptions.length > 0 && (
				<div className="empty__column">
					<h2 className="empty__title">
						<Plane size={24} className="empty__icon" />
						Create Capacity report
					</h2>

					<p>Create a report analysing tradelane capacity worldwide.</p>

					<div className="empty__create">
						<CreateWorksheetForm
							subscriptions={capacitySubscriptions}
							id={createCapacityFormId}
							onSubmit={(e) =>
								handleCreateWorksheet(e, 'capacity').catch(catchWithError)
							}
						/>
						<Button
							variant="secondary"
							type="submit"
							form={createCapacityFormId}
							isLoading={isLoading === 'capacity'}
							isDisabled={!!isLoading}
						>
							Create report
						</Button>
					</div>
				</div>
			)}
			<div className="empty__column">
				<h2 className="empty__title">Recent reports</h2>

				<ul className="empty__list">
					{mostRecentWorksheets.map((worksheet) => (
						<li className="empty__item" key={worksheet.id}>
							<Link to={`/webtool/${worksheet.id}`}>
								{worksheet.reportDataType === 'capacity' ? (
									<Plane size={16} />
								) : (
									<Package size={16} />
								)}
								<span>{worksheet.name}</span>
							</Link>
						</li>
					))}
				</ul>

				<Button variant="secondary" onClick={onWorksheetBrowserOpen}>
					Browse all reports
				</Button>
			</div>
		</div>
	);
};

export default WebToolEmptyState;
