import classNames from 'classnames';
import { format } from 'date-fns';
import {
	CalendarClock,
	CalendarPlus,
	Package,
	Plane,
	Share2,
	Trash2,
} from 'lucide-react';
import { MouseEvent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Flex from 'ui/components/Flex/Flex';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import { WorksheetBase } from 'utils/api/WebToolAPI';
import { catchWithError } from 'utils/helpers/catchHandlers';
import { useWebToolContext } from '../../routes/web-tool/WebToolContext';

type WorksheetBrowserItemProps = {
	worksheet: WorksheetBase;
	onClick?: (e: MouseEvent) => void;
	onScheduleClick?: (e: MouseEvent) => void;
};

const WorksheetBrowserItem = ({
	worksheet,
	onClick,
	onScheduleClick,
}: WorksheetBrowserItemProps) => {
	const { openWorksheetIds, deleteWorksheet } = useWebToolContext();

	const handleDelete = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		deleteWorksheet(worksheet.id).catch(catchWithError);
	};

	const isSchedulingEnabled = useMemo(() => {
		return !worksheet.supportWorksheet;
	}, [worksheet]);

	const isOpen = openWorksheetIds.includes(worksheet.id);
	const Icon = worksheet.reportDataType === 'capacity' ? Plane : Package;

	return (
		<li className="worksheet-browser__item" onClick={onClick}>
			<Link to={`/webtool/${worksheet.id}`}>
				<div className="worksheet-browser__item-wrapper">
					<Icon
						className={classNames(
							'worksheet-browser__item-icon',
							isOpen
								? 'worksheet-browser__item-icon--open'
								: 'worksheet-browser__item-icon--closed'
						)}
						size={16}
					/>
					<div className="worksheet-browser__item-content">
						<span className="worksheet-browser__item-name">
							{worksheet.name}
						</span>
						{worksheet.shareDetails && (
							<Tooltip>
								<Tooltip.Trigger>
									<Flex gap={4} alignItems="center">
										<Share2 size={14} />
										<span className="worksheet-browser__item-date">
											{format(worksheet.createdAt, `dd MMM yyyy 'at' HH:mm`)}
										</span>
									</Flex>
								</Tooltip.Trigger>
								<Tooltip.Content>
									Shared by {worksheet.shareDetails.name}
								</Tooltip.Content>
							</Tooltip>
						)}
					</div>
					<div className="worksheet-browser__item-actions">
						{onScheduleClick && isSchedulingEnabled && (
							<button
								className={classNames(
									'worksheet-browser__item-action worksheet-browser__item-action--schedule',
									!!worksheet.schedule &&
										'worksheet-browser__item-action--schedule-active'
								)}
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									onScheduleClick(e);
								}}
							>
								{worksheet.schedule ? (
									<CalendarClock size={16} />
								) : (
									<CalendarPlus size={16} />
								)}
							</button>
						)}
						<button
							className="worksheet-browser__item-action worksheet-browser__item-action--delete"
							onClick={handleDelete}
						>
							<Trash2 size={16} />
						</button>
					</div>
				</div>
			</Link>
		</li>
	);
};

export default WorksheetBrowserItem;
