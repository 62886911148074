import { SortingState } from '@tanstack/react-table';
import * as objectPath from 'object-path-immutable';
import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { ToastType } from 'ui/components/Toaster/Toast';
import WebToolAPI, {
	DataFieldMetricSegment,
	DataFieldMetricType,
	Dimension,
	GetWorksheetPreviewResponse,
	QueryStatus,
	ReportColumn,
	ReportColumnGroup,
	UpdateWorksheetResponse,
	Worksheet,
	WORKSHEET_DATA_FIELD_DIMENSIONS_KEYS,
	WorksheetAction,
	WorksheetConfigDataField,
	WorksheetConfigField,
	WorksheetDataFieldState,
	WorksheetDataFieldTimeSeriesState,
	WorksheetDataFieldVariationState,
	WorksheetExportFormat,
	WorksheetParameter,
	WorksheetParametersState,
	WorksheetSchedule,
	WorksheetState,
} from 'utils/api/WebToolAPI';
import { WebToolBreaksGroupItem } from 'utils/api/WebToolGroupAPI';
import APIError from 'utils/errors/APIError';
import { catchWithError } from 'utils/helpers/catchHandlers';
import { distinct, isPrimitiveArrayEqual } from 'utils/helpers/comparison';
import { downloadFile } from 'utils/helpers/file';
import { createToast } from 'utils/helpers/toast';
import useStateRef from 'utils/hooks/useStateRef';
import { sanitizeAwbSerialNumbers } from './AWBSerialNumber/AWBSerialNumbersModal';
import { LayoutBuilderMode } from './LayoutBuilder/LayoutBuilder';
import { WorksheetPreviewStatus } from './WorksheetPreview';

type DisabledGroupOptionsMap = Record<string, string[]>;
type DisabledDataFieldMap = Record<string, [boolean, boolean, boolean]>;

type WorksheetDerivedState = {
	selectedFields: WorksheetConfigField[];
	selectedFieldDimensions: Dimension[];
	selectedDataFieldDimensions: Dimension[];
	disabledParameters: Partial<Record<WorksheetParameter, true>>;
	disabledFields: Record<string, true>;
	disabledGroupOptions: DisabledGroupOptionsMap;
	disabledDataFields: DisabledDataFieldMap;
	disabledDataFieldMetrics: Array<DataFieldMetricType>;
	layoutBuilderMode: LayoutBuilderMode;
};

type WorksheetValidationResult = {
	validationErrors: string[];
	updatedState: WorksheetState;
};

type WorksheetContextValue = {
	worksheet: Worksheet;
	weightBreakGroups: WebToolBreaksGroupItem[];

	queryStatus?: QueryStatus;
	setQueryStatus: Dispatch<SetStateAction<QueryStatus | undefined>>;

	lastExportFormat?: WorksheetExportFormat;

	state: WorksheetState;
	setState: Dispatch<SetStateAction<WorksheetState>>;
	setDeepState: SetDeepState;

	derivedState: WorksheetDerivedState;

	isReportOptionsOpen: boolean;
	setIsReportOptionsOpen: (isOpen: boolean) => void;

	isMoveToBackgroundAvailable: boolean;
	setIsMoveToBackgroundAvailable: (isOpen: boolean) => void;

	isParametersPanelOpen: boolean;
	setIsParametersPanelOpen: (isOpen: boolean) => void;

	isOutputPanelOpen: boolean;
	setIsOutputPanelOpen: (isOpen: boolean) => void;

	isLayoutPanelOpen: boolean;
	setIsLayoutPanelOpen: (isOpen: boolean) => void;

	status: WorksheetPreviewStatus;

	saveWorksheet: () => Promise<boolean>;
	runWorksheet: (format?: WorksheetExportFormat) => Promise<void>;
	moveToBackground: () => Promise<void>;
	previewWorksheet: (
		reportId: string,
		sortingState: SortingState
	) => Promise<void>;

	validateWorksheet: () => WorksheetValidationResult;

	previewData?: GetWorksheetPreviewResponse;
	setPreviewData: Dispatch<
		SetStateAction<GetWorksheetPreviewResponse | undefined>
	>;

	isScheduleModalOpen: boolean;
	setIsScheduleModalOpen: (isOpen: boolean) => void;

	worksheetHasChanges: boolean;

	lastRunState: WorksheetState;
	setLastRunState: Dispatch<SetStateAction<WorksheetState>>;
	hasSortingChangedSinceLastRun: boolean;
	hasLayoutCompositionChangedSinceLastRun: boolean;

	schedule?: WorksheetSchedule;
	setSchedule: Dispatch<SetStateAction<WorksheetSchedule | undefined>>;

	isShareModalOpen: boolean;
	setIsShareModalOpen: (isOpen: boolean) => void;

	isReportRunningBlocked: boolean;
};

const WorksheetContext = createContext<WorksheetContextValue>(null!);

type WorksheetProviderProps = PropsWithChildren<{
	worksheet: Worksheet;
	weightBreakGroups: WebToolBreaksGroupItem[];
	state: WorksheetState;
	onStateChange: Dispatch<SetStateAction<WorksheetState>>;
}>;

export type SetDeepState = {
	(path: 'output', value: SetStateAction<WorksheetState['output']>): void;
	(
		path: 'parameters',
		value: SetStateAction<WorksheetState['parameters']>
	): void;
	(path: 'layout', value: SetStateAction<WorksheetState['layout']>): void;

	(
		path: 'output.fields',
		value: SetStateAction<WorksheetState['output']['fields']>
	): void;
	(
		path: 'output.dataFields',
		value: SetStateAction<WorksheetState['output']['dataFields']>
	): void;
	(
		path: `output.dataFields.${string}`,
		value: SetStateAction<WorksheetState['output']['dataFields'][string]>
	): void;
	(
		path: `output.dataFields.${string}.customer`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['customer']
		>
	): void;
	(
		path: `output.dataFields.${string}.customer.simple`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['customer']['simple']
		>
	): void;
	(
		path: `output.dataFields.${string}.customer.advanced`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['customer']['advanced']
		>
	): void;
	(
		path: `output.dataFields.${string}.market`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['market']
		>
	): void;
	(
		path: `output.dataFields.${string}.market.simple`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['market']['simple']
		>
	): void;
	(
		path: `output.dataFields.${string}.market.advanced`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['market']['advanced']
		>
	): void;
	(
		path: `output.dataFields.${string}.customerShare`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['customerShare']
		>
	): void;
	(
		path: `output.dataFields.${string}.customerShare.simple`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['customerShare']['simple']
		>
	): void;
	(
		path: `output.dataFields.${string}.customerShare.advanced`,
		value: SetStateAction<
			WorksheetState['output']['dataFields'][string]['customerShare']['advanced']
		>
	): void;

	(
		path: 'output.dataFieldTimeSeries',
		value: SetStateAction<WorksheetState['output']['dataFieldTimeSeries']>
	): void;
	(
		path: 'output.dataFieldTimeSeries.simple',
		value: SetStateAction<
			WorksheetState['output']['dataFieldTimeSeries']['simple']
		>
	): void;
	(
		path: 'output.dataFieldTimeSeries.advanced',
		value: SetStateAction<
			WorksheetState['output']['dataFieldTimeSeries']['advanced']
		>
	): void;
	(
		path: 'output.currency',
		value: SetStateAction<WorksheetState['output']['currency']>
	): void;
	(
		path: 'output.distortion',
		value: SetStateAction<WorksheetState['output']['distortion']>
	): void;
	(
		path: 'output.advancedMode',
		value: SetStateAction<WorksheetState['output']['advancedMode']>
	): void;
	(
		path: 'output.fieldOptionGroups',
		value: SetStateAction<WorksheetState['output']['fieldOptionGroups']>
	): void;
	(
		path: `output.fieldOptionGroups.${string}`,
		value: SetStateAction<WorksheetState['output']['fieldOptionGroups'][string]>
	): void;
	(
		path: `output.webToolGroups`,
		value: SetStateAction<WorksheetState['output']['webToolGroups']>
	): void;
	(
		path: `output.webToolGroups.${string}`,
		value: SetStateAction<WorksheetState['output']['webToolGroups'][string]>
	): void;

	(
		path: 'parameters.advancedMode',
		value: SetStateAction<WorksheetState['parameters']['advancedMode']>
	): void;
	(
		path: 'parameters.location',
		value: SetStateAction<WorksheetState['parameters']['location']>
	): void;
	(
		path: 'parameters.dateRange',
		value: SetStateAction<WorksheetState['parameters']['dateRange']>
	): void;
	(
		path: 'parameters.awb',
		value: SetStateAction<WorksheetState['parameters']['awb']>
	): void;
	(
		path: 'parameters.breakdown',
		value: SetStateAction<WorksheetState['parameters']['breakdown']>
	): void;

	(
		path: 'parameters.location.originLocations',
		value: SetStateAction<
			WorksheetState['parameters']['location']['originLocations']
		>
	): void;
	(
		path: 'parameters.location.destinationLocations',
		value: SetStateAction<
			WorksheetState['parameters']['location']['destinationLocations']
		>
	): void;
	(
		path: 'parameters.location.includeReverseLocations',
		value: SetStateAction<
			WorksheetState['parameters']['location']['includeReverseLocations']
		>
	): void;
	(
		path: 'parameters.location.tradelanes',
		value: SetStateAction<
			WorksheetState['parameters']['location']['tradelanes']
		>
	): void;
	(
		path: 'parameters.location.cassAreas',
		value: SetStateAction<WorksheetState['parameters']['location']['cassAreas']>
	): void;
	(
		path: 'parameters.location.releasedCountriesOnly',
		value: SetStateAction<
			WorksheetState['parameters']['location']['releasedCountriesOnly']
		>
	): void;

	(
		path: 'parameters.dateRange.selectedType',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['selectedType']
		>
	): void;
	(
		path: 'parameters.dateRange.absolute',
		value: SetStateAction<WorksheetState['parameters']['dateRange']['absolute']>
	): void;
	(
		path: 'parameters.dateRange.absolute.startDate',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['absolute']['startDate']
		>
	): void;
	(
		path: 'parameters.dateRange.absolute.endDate',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['absolute']['endDate']
		>
	): void;

	(
		path: 'parameters.dateRange.relative',
		value: SetStateAction<WorksheetState['parameters']['dateRange']['relative']>
	): void;
	(
		path: 'parameters.dateRange.relative.period',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['relative']['period']
		>
	): void;
	(
		path: 'parameters.dateRange.relative.periodCount',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['relative']['periodCount']
		>
	): void;

	(
		path: 'parameters.dateRange.compare',
		value: SetStateAction<WorksheetState['parameters']['dateRange']['compare']>
	): void;
	(
		path: 'parameters.dateRange.compare.startDate',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['compare']['startDate']
		>
	): void;
	(
		path: 'parameters.dateRange.compare.endDate',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['compare']['endDate']
		>
	): void;
	(
		path: 'parameters.dateRange.compare.compareStartDate',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['compare']['compareStartDate']
		>
	): void;
	(
		path: 'parameters.dateRange.compare.compareEndDate',
		value: SetStateAction<
			WorksheetState['parameters']['dateRange']['compare']['compareEndDate']
		>
	): void;

	(
		path: 'parameters.awb.serialNumber',
		value: SetStateAction<WorksheetState['parameters']['awb']['serialNumber']>
	): void;
	(
		path: 'parameters.awb.airlinePrefix',
		value: SetStateAction<WorksheetState['parameters']['awb']['airlinePrefix']>
	): void;

	(
		path: 'parameters.breakdown.airlines',
		value: SetStateAction<WorksheetState['parameters']['breakdown']['airlines']>
	): void;
	(
		path: 'parameters.breakdown.freightForwarders',
		value: SetStateAction<
			WorksheetState['parameters']['breakdown']['freightForwarders']
		>
	): void;
	(
		path: 'parameters.breakdown.weightBreakGroupId',
		value: SetStateAction<
			WorksheetState['parameters']['breakdown']['weightBreakGroupId']
		>
	): void;
	(
		path: 'parameters.breakdown.weightBreakGroupEmbedded',
		value: SetStateAction<
			WorksheetState['parameters']['breakdown']['weightBreakGroupEmbedded']
		>
	): void;
	(
		path: 'parameters.breakdown.weightBreaks',
		value: SetStateAction<
			WorksheetState['parameters']['breakdown']['weightBreaks']
		>
	): void;
	(
		path: 'parameters.breakdown.specialHandlingCodes',
		value: SetStateAction<
			WorksheetState['parameters']['breakdown']['specialHandlingCodes']
		>
	): void;
	(
		path: 'parameters.breakdown.aircraftModels',
		value: SetStateAction<
			WorksheetState['parameters']['breakdown']['aircraftModels']
		>
	): void;
	(
		path: 'parameters.breakdown.capacityTypes',
		value: SetStateAction<
			WorksheetState['parameters']['breakdown']['capacityTypes']
		>
	): void;
	(
		path: 'parameters.specialHandling.highLevels',
		value: SetStateAction<
			WorksheetState['parameters']['specialHandling']['highLevels']
		>
	): void;
	(
		path: 'parameters.specialHandling.commodityMidLevels',
		value: SetStateAction<
			WorksheetState['parameters']['specialHandling']['commodityMidLevels']
		>
	): void;
	(
		path: 'parameters.specialHandling.serviceMidLevels',
		value: SetStateAction<
			WorksheetState['parameters']['specialHandling']['serviceMidLevels']
		>
	): void;
	(
		path: 'layout.displayReportParameters',
		value: SetStateAction<WorksheetState['layout']['displayReportParameters']>
	): void;
	(
		path: 'layout.dataFieldSortOrder',
		value: SetStateAction<WorksheetState['layout']['dataFieldSortOrder']>
	): void;
	(
		path: 'layout.fieldSortOrder',
		value: SetStateAction<WorksheetState['layout']['fieldSortOrder']>
	): void;
	(
		path: 'layout.sorting',
		value: SetStateAction<WorksheetState['layout']['sorting']>
	): void;
	(
		path: 'layout.columnsSortOrder',
		value: SetStateAction<WorksheetState['layout']['columnsSortOrder']>
	): void;
	(
		path: 'layout.columnsSortDirections',
		value: SetStateAction<WorksheetState['layout']['columnsSortDirections']>
	): void;
};

export function hasValuesForParameter(
	state: WorksheetParametersState,
	parameter: WorksheetParameter
): boolean {
	switch (parameter) {
		case 'origin':
			return state.location.originLocations.length > 0;
		case 'destination':
			return state.location.destinationLocations.length > 0;
		case 'compare-date-range':
			return state.dateRange.selectedType === 'compare';
		case 'airline':
			return state.breakdown.airlines.length > 0;
		case 'freight-forwarder':
			return state.breakdown.freightForwarders.length > 0;
		case 'aircraft-model':
			return state.breakdown.aircraftModels.length > 0;
		case 'capacity-type':
			return state.breakdown.capacityTypes.length > 0;
		case 'weight-break-set':
			return Boolean(state.breakdown.weightBreakGroupId) && state.advancedMode;
		case 'weight-break':
			return state.breakdown.weightBreaks.length > 0;
		case 'special-handling-code':
			return state.breakdown.specialHandlingCodes.length > 0;
		case 'sph-high-level':
			return state.specialHandling.highLevels.length > 0;
		case 'commodity-mid-level':
			return state.specialHandling.commodityMidLevels.length > 0;
		case 'service-mid-level':
			return state.specialHandling.serviceMidLevels.length > 0;
		case 'tradelane':
			return state.location.tradelanes.length > 0 && state.advancedMode;
		case 'cass-area':
			return state.location.cassAreas.length > 0 && state.advancedMode;
		case 'released-countries-only':
			return state.location.releasedCountriesOnly && state.advancedMode;
		case 'air-waybill':
			return (
				(state.awb.airlinePrefix.length > 0 ||
					state.awb.serialNumber.length > 0) &&
				state.advancedMode
			);
		default:
			parameter satisfies never;
			return false;
	}
}

export function resetValuesForParameter(
	// Extend SetDeepState to allow silent boolean as third parameter
	setDeepState: SetDeepState,
	parameter: WorksheetParameter
) {
	switch (parameter) {
		case 'origin':
			setDeepState('parameters.location.originLocations', []);
			break;
		case 'destination':
			setDeepState('parameters.location.destinationLocations', []);
			break;
		case 'airline':
			setDeepState('parameters.breakdown.airlines', []);
			break;
		case 'freight-forwarder':
			setDeepState('parameters.breakdown.freightForwarders', []);
			break;
		case 'weight-break-set':
			setDeepState('parameters.breakdown.weightBreakGroupId', undefined);
			setDeepState('parameters.breakdown.weightBreakGroupEmbedded', undefined);
			setDeepState('parameters.breakdown.weightBreaks', []);
			break;
		case 'weight-break':
			setDeepState('parameters.breakdown.weightBreaks', []);
			break;
		case 'special-handling-code':
			setDeepState('parameters.breakdown.specialHandlingCodes', '');
			break;
		case 'tradelane':
			setDeepState('parameters.location.tradelanes', []);
			break;
		case 'cass-area':
			setDeepState('parameters.location.cassAreas', []);
			break;
		case 'released-countries-only':
			setDeepState('parameters.location.releasedCountriesOnly', false);
			break;
		case 'air-waybill':
			setDeepState('parameters.awb.airlinePrefix', '');
			setDeepState('parameters.awb.serialNumber', '');
			break;
		case 'sph-high-level':
			setDeepState('parameters.specialHandling.highLevels', []);
			break;
		case 'commodity-mid-level':
			setDeepState('parameters.specialHandling.commodityMidLevels', []);
			break;
		case 'service-mid-level':
			setDeepState('parameters.specialHandling.serviceMidLevels', []);
			break;
		case 'compare-date-range':
			setDeepState('parameters.dateRange.selectedType', 'absolute');
			break;
		case 'aircraft-model':
			setDeepState('parameters.breakdown.aircraftModels', []);
			break;
		case 'capacity-type':
			setDeepState('parameters.breakdown.capacityTypes', []);
			break;
		default:
			parameter satisfies never;
	}
}

function displayValidationMessages(
	response: Error | APIError | UpdateWorksheetResponse
) {
	if (response instanceof APIError && response.fieldErrors) {
		const errorMessages = response.fieldErrors.flatMap((e) => e[1]);
		if (errorMessages.length > 0) {
			createToast(
				ToastType.ERROR,
				{
					message: 'Report validation failure',
					hint: errorMessages,
				},
				{
					duration: Infinity,
				}
			);
		}
	}
}

export const WorksheetProvider = ({
	worksheet,
	weightBreakGroups,
	state,
	onStateChange: propsOnStateChange,
	children,
}: WorksheetProviderProps) => {
	const [isReportOptionsOpen, setIsReportOptionsOpen] = useState(false);
	const [isMoveToBackgroundAvailable, setIsMoveToBackgroundAvailable] =
		useState(false);
	const [isParametersPanelOpen, setIsParametersPanelOpen] = useState(true);
	const [isOutputPanelOpen, setIsOutputPanelOpen] = useState(true);
	const [isLayoutPanelOpen, setIsLayoutPanelOpen] = useState(false);
	const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);

	const [worksheetHasChanges, setWorksheetHasChanges] = useState(false);

	const onStateChange = useCallback(
		(newState: SetStateAction<WorksheetState>) => {
			propsOnStateChange(newState);

			const hasChanges = JSON.stringify(newState) !== JSON.stringify(state);
			setWorksheetHasChanges(hasChanges);
		},
		[]
	);

	const [queryStatus, setQueryStatus] = useState<QueryStatus | undefined>(
		worksheet.queryStatus ?? undefined
	);

	const activeReportId = useRef<string | null>(null);
	const activeReportTimeout = useRef<number | null>(null);

	const [schedule, setSchedule] = useState<WorksheetSchedule | undefined>(
		worksheet.schedule ?? undefined
	);

	const [previewData, setPreviewData] = useState<
		GetWorksheetPreviewResponse | undefined
	>(undefined);

	/**
	 * This cleans up the sorting state after the preview has run. If there was previously
	 * a pivoted column sorted (one with a composite ID), and the user has changed the pivot,
	 * then the sorted ID column will not exist in the output. Therefore, we remove it, to avoid showing
	 * invalid multi-sort states.
	 *
	 * CIS-2501
	 */
	useEffect(() => {
		if (!previewData) return;

		const getColumnIds = (
			groupOrColumn: ReportColumnGroup | ReportColumn
		): string[] => {
			if ('items' in groupOrColumn) {
				return groupOrColumn.items.flatMap(getColumnIds);
			} else {
				return [groupOrColumn.id];
			}
		};

		const columnIds = previewData.columns.flatMap(getColumnIds);
		const filteredSorting = state.layout.sorting.filter((sort) =>
			columnIds.includes(sort.id)
		);

		// This update causes the save button to become active, so we want
		// to be careful about when to set the state
		if (filteredSorting.length !== state.layout.sorting.length) {
			setDeepState('layout.sorting', filteredSorting);
		}

		setLastRunState((prevState) => ({
			...prevState,
			layout: {
				...prevState.layout,
				sorting: filteredSorting,
			},
		}));
	}, [previewData]);

	const [status, setStatus, statusRef] = useStateRef(
		WorksheetPreviewStatus.Hidden
	);

	const [isReportRunningBlocked, setIsReportRunningBlocked] =
		useStateRef(false);

	const [lastExportFormat, setLastExportFormat] = useState<
		WorksheetExportFormat | undefined
	>(undefined);

	useEffect(() => {
		async function restorePreview() {
			if (queryStatus?.executionStatus === 'SUCCESS') {
				setStatus(WorksheetPreviewStatus.LoadingPreview);

				const previewData = await WebToolAPI.getWorksheetPreview(
					worksheet.id,
					queryStatus.id,
					state.layout.sorting
				);

				setPreviewData(previewData);
				setStatus(WorksheetPreviewStatus.ReportCompleted);
			}
		}

		restorePreview().catch(() => {
			setStatus(WorksheetPreviewStatus.Error);
		});
	}, []);

	const [lastRunState, setLastRunState] = useState<WorksheetState>(
		worksheet.initialState
	);

	const hasSortingChangedSinceLastRun = useMemo(() => {
		const stringifiedLastRunSort = JSON.stringify(lastRunState.layout.sorting);
		const stringifiedCurrentSort = JSON.stringify(state.layout.sorting);
		if (stringifiedLastRunSort !== stringifiedCurrentSort) return true;

		return false;
	}, [
		// Need to use this because the sorting state contains objects
		state.layout.sorting,
		state.layout.columnsSortDirections,
		state.layout.fieldSortOrder,
		state.layout.dataFieldSortOrder,
		lastRunState,
	]);

	const hasLayoutCompositionChangedSinceLastRun = useMemo(() => {
		// Have the selected fields changed?
		const currentFields = state.output.fields;
		const lastRunFields = lastRunState.output.fields;
		if (!isPrimitiveArrayEqual(currentFields, lastRunFields, false)) {
			return true;
		}

		// Have the selected data fields changed?
		const currentDataFields = state.output.dataFields;
		const lastRunDataFields = lastRunState.output.dataFields;
		if (
			// The fields themselves?
			!isPrimitiveArrayEqual(
				Object.keys(currentDataFields),
				Object.keys(lastRunDataFields),
				false
			) ||
			// Or their variations?
			Object.keys(currentDataFields).some((field) => {
				const currentDataField = currentDataFields[field];
				const initialDataField = lastRunDataFields[field];
				if (!currentDataField || !initialDataField) {
					return false;
				}

				for (const segment of [
					'customer',
					'market',
					'customerShare',
				] as const) {
					if (
						currentDataField[segment].simple !==
						initialDataField[segment].simple
					)
						return true;

					for (let variation = 0; variation < 4; variation++) {
						if (
							currentDataField[segment].advanced[variation] !==
							initialDataField[segment].advanced[variation]
						) {
							return true;
						}
					}
				}

				return false;
			})
		) {
			return true;
		}

		// Have the selected columns changed?
		const currentColumnsSortOrder = state.layout.columnsSortOrder;
		const lastRunColumnsSortOrder = lastRunState.layout.columnsSortOrder;
		if (
			!isPrimitiveArrayEqual(currentColumnsSortOrder, lastRunColumnsSortOrder)
		) {
			return true;
		}

		// Have the selected column sort directions changed?
		const currentColumnsSortDirections = state.layout.columnsSortDirections;
		const lastRunColumnsSortDirections =
			lastRunState.layout.columnsSortDirections;
		for (const column of Object.keys(currentColumnsSortDirections)) {
			if (
				currentColumnsSortDirections[column] !==
				lastRunColumnsSortDirections[column]
			) {
				return true;
			}
		}

		// Have the selected field sort orders changed?
		const currentFieldSortOrder = state.layout.fieldSortOrder;
		const lastRunFieldSortOrder = lastRunState.layout.fieldSortOrder;
		if (!isPrimitiveArrayEqual(currentFieldSortOrder, lastRunFieldSortOrder)) {
			return true;
		}

		// Have the selected data field sort orders changed?
		const currentDataFieldSortOrder = state.layout.dataFieldSortOrder;
		const lastRunDataFieldSortOrder = lastRunState.layout.dataFieldSortOrder;
		if (
			!isPrimitiveArrayEqual(
				currentDataFieldSortOrder,
				lastRunDataFieldSortOrder
			)
		) {
			return true;
		}

		return false;
	}, [
		state.output.fields,
		state.output.dataFields,
		state.layout.columnsSortOrder,
		state.layout.columnsSortDirections,
		state.layout.fieldSortOrder,
		state.layout.dataFieldSortOrder,
		lastRunState,
	]);

	const setDeepState: SetDeepState = (
		path: string,
		value: SetStateAction<any>
	) => {
		onStateChange((state) => {
			const currentValue = objectPath.get(state, path);

			const newValue =
				typeof value === 'function' ? value(currentValue) : value;

			return objectPath.set(state, path, newValue);
		});
	};

	const selectedFields = useMemo(
		() =>
			worksheet.config.fieldGroups
				.flatMap((fieldGroup) => fieldGroup.fields)
				.filter((field) => state.output.fields.includes(field.id)),
		[worksheet.config.fieldGroups, state.output.fields]
	);

	const selectedFieldDimensions = useMemo(
		() =>
			selectedFields
				.flatMap((field) => {
					if (field.optionGroup) {
						return (
							state.output.fieldOptionGroups[field.optionGroup].flatMap(
								(option) => {
									return worksheet.config.dimensions[`${field.id}-${option}`];
								}
							) ?? []
						);
					} else {
						return [worksheet.config.dimensions[field.id]];
					}
				})
				.filter((dimension): dimension is Dimension => dimension !== undefined),
		[
			worksheet.config.dimensions,
			worksheet.config.optionGroups,
			selectedFields,
			state.output.fieldOptionGroups,
		]
	);

	const getDataFieldDimensions = (
		dataField: WorksheetConfigDataField
	): Dimension[] => {
		const dataFieldSelection = state.output.dataFields[dataField.id];
		if (!dataFieldSelection) {
			return [];
		}

		const allVariations = [
			'ty',
			'ly',
			'yoy',
			'yoyc',
			worksheet.config.dataFieldTimeSeries.weekOverWeek ? 'wow' : null,
			worksheet.config.dataFieldTimeSeries.weekOverWeek ? 'wowc' : null,
		].filter((x): x is DataFieldMetricType => !!x);

		// Important note: When month over month comes in, we need to make sure to watch out for the case in which MoM is enabled but WoW is not. In this case,
		// MoM will take indices 4 and 5, which are typically occupied by WoW. This might lead to issues in other code where the index is used to determine the
		// variation type.

		const globallyEnabledVariations = allVariations.filter((_, index) => {
			if (state.output.advancedMode) {
				return state.output.dataFieldTimeSeries.advanced[index];
			} else {
				return state.output.dataFieldTimeSeries.simple[index];
			}
		});

		const enabledSegments = [
			dataField.isCustomer && 'customer',
			dataField.isMarket && 'market',
			dataField.isCustomerShare && 'customerShare',
		].filter((x): x is 'customerShare' | 'market' | 'customer' => !!x);

		const allDimensions: Dimension[] = [];

		for (const variation of globallyEnabledVariations) {
			for (const segment of enabledSegments) {
				const locallyEnabledVariations = dataFieldSelection[segment];
				const segmentKey: DataFieldMetricSegment =
					segment === 'customerShare' ? 'customer-share' : segment;
				const includeDistorted =
					// distortion included by default when no options provided, such as non IATA subscriptions
					state.output.distortion.length === 0 ||
					// Otherwise it's only included if selected
					state.output.distortion.includes('distorted');
				const includeUndistorted =
					state.output.distortion.includes('undistorted');

				const variationIndex = allVariations.indexOf(variation);

				if (
					state.output.advancedMode &&
					!locallyEnabledVariations.advanced[variationIndex]
				) {
					continue;
				}

				if (!state.output.advancedMode && !locallyEnabledVariations.simple) {
					continue;
				}

				let dimensionValues = dataField.isCurrencyBased
					? state.output.currency.flatMap((currencyCode) => {
							const distortedDimensionId = `${variation}-${segmentKey}-${
								dataField.id
							}-${currencyCode.toLowerCase()}`;
							const undistortedDimensionId = `${distortedDimensionId}-undistorted`;

							// If the undistorted version of the Data Field exists, then decide which version to return
							if (worksheet.config.dimensions[undistortedDimensionId]) {
								return [
									includeDistorted ? distortedDimensionId : null,
									includeUndistorted ? undistortedDimensionId : null,
								].filter((x): x is string => !!x);
							}

							return [distortedDimensionId];
						})
					: [`${variation}-${segmentKey}-${dataField.id}`];

				const dimensions = dimensionValues
					.map((dimensionValue) => worksheet.config.dimensions[dimensionValue])
					.filter((x): x is Dimension => !!x);

				allDimensions.push(...dimensions);
			}
		}

		return allDimensions;
	};

	const selectedDataFieldDimensions = useMemo(
		() =>
			worksheet.config.dataFields
				.flatMap((dataField) => {
					if (!state.output.dataFields[dataField.id]) {
						return [];
					}

					return getDataFieldDimensions(dataField);
				})
				.filter((x): x is Dimension => !!x),
		[
			worksheet.config.dataFields,
			state.output.dataFields,
			state.output.dataFieldTimeSeries.advanced,
			state.output.dataFieldTimeSeries.simple,
			state.output.advancedMode,
			state.output.currency,
			state.output.distortion,
		]
	);

	const disabledParameters = useMemo(() => {
		// Return an object of disabled parameters based on the current list of parameters selected
		const disabledParameters: Partial<Record<WorksheetParameter, true>> = {};
		for (const restriction of worksheet.config.restrictions) {
			if (
				restriction.parameters.some((p) =>
					hasValuesForParameter(state.parameters, p)
				)
			) {
				for (const disabledParameter of restriction.disabledParameters) {
					disabledParameters[disabledParameter] = true;
				}
			}
		}

		for (const restriction of worksheet.config.restrictions) {
			if (selectedFields.some((s) => restriction.fields.includes(s.id))) {
				for (const disabledParameter of restriction.disabledParameters) {
					disabledParameters[disabledParameter] = true;
				}
			}
		}

		for (const disabledParameter of Object.keys(
			disabledParameters
		) as WorksheetParameter[]) {
			if (hasValuesForParameter(state.parameters, disabledParameter)) {
				resetValuesForParameter(setDeepState, disabledParameter);
			}
		}

		return disabledParameters;
	}, [
		worksheet.config.restrictions,
		state.parameters,
		state.output,
		selectedFields,
	]);

	const disabledFields = useMemo(() => {
		// Return an object of disabled parameters based on the current list of parameters selected
		const disabledFields: Record<string, true> = {};
		for (const restriction of worksheet.config.restrictions) {
			if (
				restriction.parameters.some((p) =>
					hasValuesForParameter(state.parameters, p)
				) ||
				selectedFields.some((s) => restriction.fields.includes(s.id))
			) {
				for (const disabledParameter of restriction.disabledFields) {
					disabledFields[disabledParameter] = true;
				}
			}
		}

		const disabledFieldKeys = Object.keys(disabledFields);
		if (
			state.output.fields.some((field) => disabledFieldKeys.includes(field))
		) {
			setDeepState('output.fields', (fields) =>
				fields.filter((field) => !disabledFieldKeys.includes(field))
			);
		}

		return disabledFields;
	}, [worksheet.config.restrictions, state.parameters, selectedFields]);

	const disabledGroupOptions = useMemo(() => {
		return deriveDisabledGroupOptions(
			worksheet,
			state,
			setDeepState,
			selectedFields
		);
	}, [
		worksheet.config.restrictions,
		worksheet.config.optionGroups,
		state.parameters,
		state.output.fieldOptionGroups,
		selectedFields,
	]);

	const disabledDataFields = useMemo(() => {
		// Return an object of disabled parameters based on the current list of parameters selected
		const disabledDataFields: DisabledDataFieldMap = {};
		for (const restriction of worksheet.config.restrictions) {
			if (
				restriction.parameters.some((p) =>
					hasValuesForParameter(state.parameters, p)
				) ||
				selectedFields.some((s) => restriction.fields.includes(s.id))
			) {
				for (const disabledParameter of restriction.disabledDataFields) {
					let disabledSegments = disabledDataFields[disabledParameter.id];
					if (disabledSegments) {
						// If there are already disabled segments, we need to merge them to always returned the disabled items
						disabledSegments = disabledSegments.map((s, i) => {
							return s || disabledParameter.segments[i];
						}) as [boolean, boolean, boolean];
					} else {
						disabledSegments = disabledParameter.segments;
					}
					disabledDataFields[disabledParameter.id] = disabledSegments;
				}
			}
		}

		const disabledFieldKeys = Object.keys(disabledDataFields);
		const dataFieldIdsToUnselect = Object.keys(state.output.dataFields).filter(
			(datafieldId) => disabledFieldKeys.includes(datafieldId)
		);
		if (dataFieldIdsToUnselect.length > 0) {
			const disabledVariationState: WorksheetDataFieldVariationState = {
				simple: false,
				advanced: [false, false, false, false, false, false],
			};

			for (const dataFieldId of dataFieldIdsToUnselect) {
				const disabledDataField = disabledDataFields[dataFieldId];
				const dataFieldSelection = state.output.dataFields[dataFieldId];

				if (
					dataFieldSelection.customer.advanced.some(Boolean) &&
					disabledDataField[0]
				) {
					setDeepState(
						`output.dataFields.${dataFieldId}.customer`,
						disabledVariationState
					);
				}
				if (
					dataFieldSelection.market.advanced.some(Boolean) &&
					disabledDataField[1]
				) {
					setDeepState(
						`output.dataFields.${dataFieldId}.market`,
						disabledVariationState
					);
				}
				if (
					dataFieldSelection.customerShare.advanced.some(Boolean) &&
					disabledDataField[2]
				) {
					setDeepState(
						`output.dataFields.${dataFieldId}.customerShare`,
						disabledVariationState
					);
				}
			}
		}

		return disabledDataFields;
	}, [worksheet.config.restrictions, state.parameters, selectedFields]);

	const disabledDataFieldMetrics = useMemo(() => {
		const disabledDataFieldMetrics: Array<DataFieldMetricType> = [];
		for (const restriction of worksheet.config.restrictions) {
			if (
				restriction.parameters.some((p) =>
					hasValuesForParameter(state.parameters, p)
				) ||
				selectedFields.some((s) => restriction.fields.includes(s.id))
			) {
				disabledDataFieldMetrics.push(...restriction.disabledDataFieldMetrics);
			}
		}

		return [...new Set(disabledDataFieldMetrics)];
	}, [worksheet.config.restrictions, state.parameters, selectedFields]);

	useEffect(() => {
		if (disabledDataFieldMetrics.length === 0) return;

		// When the disabled data field metrics change, we need to disable the corresponding advanced options
		const indicesToDisable = disabledDataFieldMetrics.map((metric) =>
			WORKSHEET_DATA_FIELD_DIMENSIONS_KEYS.indexOf(metric)
		);

		const currentDataFieldsEntries = Object.entries(
			state.output.dataFields
		).map(([key, value]) => {
			const allKeys = Object.keys(value) as Array<
				keyof WorksheetDataFieldState
			>;

			for (const key of allKeys) {
				for (const index of indicesToDisable) {
					value[key].advanced[index] = false;
				}
			}

			return [key, value] as const;
		});

		setDeepState(
			'output.dataFields',
			Object.fromEntries(currentDataFieldsEntries)
		);

		setDeepState('output.dataFieldTimeSeries', (series) => {
			const advanced = series.advanced.map((value, index) => {
				return indicesToDisable.includes(index) ? false : value;
			}) as WorksheetDataFieldTimeSeriesState;

			const simple = series.simple.map((value, index) => {
				return indicesToDisable.includes(index) ? false : value;
			}) as WorksheetDataFieldTimeSeriesState;

			return {
				advanced,
				simple,
			};
		});
	}, [disabledDataFieldMetrics]);

	// Deliberately using useMemo, because When done with useEffect the toggling off of the Service Item Level flashes the Report Layout
	// Switching to useEffect while working out what is causing the duplicate payment currency issue
	useEffect(() => {
		if (state.output.currency.length === 0) return;

		const paymentCurrencyCodeId = 'payment-currency-code';
		const paymentCurrentCodeSelected = state.output.fields.includes(
			paymentCurrencyCodeId
		);

		function selectPaymentCurrencyCode() {
			setDeepState('output.fields', (fields) => [
				...fields,
				paymentCurrencyCodeId,
			]);
		}
		function deselectPaymentCurrencyCode() {
			setDeepState('output.fields', (fields) =>
				fields.filter((field) => field !== paymentCurrencyCodeId)
			);
		}

		if (disabledFields[paymentCurrencyCodeId]) {
			if (paymentCurrentCodeSelected) deselectPaymentCurrencyCode();
			return;
		}

		// Special case for LOCAL
		const localCurrencySelected = state.output.currency.includes('LOCAL');
		if (localCurrencySelected && !paymentCurrentCodeSelected)
			return selectPaymentCurrencyCode();

		if (!localCurrencySelected && paymentCurrentCodeSelected)
			return deselectPaymentCurrencyCode();
	}, [state.output.currency, disabledFields]);

	const layoutBuilderMode = useMemo(() => {
		if (state.layout.columnsSortOrder.length > 0) {
			return LayoutBuilderMode.PIVOT;
		}

		return LayoutBuilderMode.TABLE;
	}, [state.layout.columnsSortOrder]);

	const derivedState: WorksheetDerivedState = useMemo(
		() => ({
			selectedFields,
			selectedFieldDimensions,
			selectedDataFieldDimensions,
			disabledParameters,
			disabledFields,
			disabledGroupOptions,
			disabledDataFieldMetrics,
			disabledDataFields,
			layoutBuilderMode,
		}),
		[
			selectedFields,
			selectedFieldDimensions,
			selectedDataFieldDimensions,
			disabledParameters,
			disabledFields,
			disabledGroupOptions,
			disabledDataFieldMetrics,
			disabledDataFields,
			layoutBuilderMode,
		]
	);

	const saveWorksheet = async () => {
		const { validationErrors, updatedState } = validateWorksheet();

		if (validationErrors.length > 0) {
			createToast(ToastType.ERROR, {
				message: 'Validation failed',
				hint: validationErrors,
			});

			return false;
		}

		await WebToolAPI.updateWorksheetWithAction(
			worksheet.id,
			updatedState,
			WorksheetAction.SAVE
		);

		setWorksheetHasChanges(false);
		return true;
	};

	const previewWorksheet = useCallback(
		async (reportId: string, sortingState?: SortingState) => {
			setLastExportFormat('screen');
			setStatus(WorksheetPreviewStatus.LoadingPreview);

			setIsParametersPanelOpen(false);
			setIsOutputPanelOpen(false);
			setIsLayoutPanelOpen(false);

			const previewData = await WebToolAPI.getWorksheetPreview(
				worksheet.id,
				reportId,
				sortingState ?? []
			);

			setPreviewData(previewData);
			setStatus(WorksheetPreviewStatus.ReportCompleted);
		},
		[]
	);

	const downloadExport = useCallback(async (reportId: string) => {
		const response = await WebToolAPI.getWorksheetExportDownload(reportId);

		downloadFile(response.url, response.originalFileName);

		return response.originalFileName;
	}, []);

	const validateWorksheet = useCallback((): WorksheetValidationResult => {
		const errors: string[] = [];
		const updatedState = { ...state };

		if (derivedState.selectedDataFieldDimensions.length === 0) {
			errors.push('Please select at least one data field');
		}

		// WoW selections

		if (
			derivedState.selectedDataFieldDimensions.some(
				(dimension) =>
					dimension.id.startsWith('wow-') || dimension.id.startsWith('wowc-')
			) &&
			!(
				state.output.fields.includes('data-week') ||
				state.output.fields.includes('data-week-period')
			)
		) {
			errors.push(
				'Please select either the Data Week field or the Data Week Period field to enable Week Over Week comparisons.'
			);
		}

		const dimensionSelectionLimit =
			worksheet.config.dimensionSelectionLimit ?? 48;

		const totalColumns =
			derivedState.selectedFieldDimensions.length +
			derivedState.selectedDataFieldDimensions.length;

		if (totalColumns > dimensionSelectionLimit) {
			errors.push(
				`Please adjust your selections, ${totalColumns}, so you have no more than ${dimensionSelectionLimit} columns of data`
			);
		}

		const awbAirlinePrefixRegex = /^\d{3}$/g;
		if (
			state.parameters.awb.airlinePrefix.length > 0 &&
			!state.parameters.awb.airlinePrefix.match(awbAirlinePrefixRegex)
		) {
			errors.push('AWB Airline Prefixes must be 3 digit zero-padded numbers');
		}

		// Relative date range

		if (
			state.parameters.dateRange.selectedType === 'relative' &&
			state.parameters.dateRange.relative.periodCount <= 0
		) {
			errors.push('Relative date expects values greater than zero');
		}

		// AWB Serial numbers

		const awbSerialNumberRegex = /^\d{8}$/g;
		const sanitizedSerialNumbers = sanitizeAwbSerialNumbers(
			state.parameters.awb.serialNumber
		);

		if (
			state.parameters.awb.serialNumber.length > 0 &&
			sanitizedSerialNumbers.some(
				(serialNumber) => !serialNumber.match(awbSerialNumberRegex)
			)
		) {
			errors.push(
				'AWB Serial Numbers must be a comma or semicolon separated list of 8 digit numbers.'
			);
		}

		if (
			state.parameters.breakdown.specialHandlingCodes.length > 0 &&
			state.parameters.breakdown.specialHandlingCodes
				.split(/[,;\s]/g)
				.filter((code) => code.length > 0 && code.length !== 3).length > 0
		) {
			errors.push('Special Handling Codes must be 3 characters long');
		}

		const selectedFieldsWithGroups = worksheet.config.fieldGroups
			.flatMap((fieldGroup) => fieldGroup.fields)
			.filter(
				(field) =>
					state.output.fields.includes(field.id) &&
					field.groupType !== undefined
			);

		for (const field of selectedFieldsWithGroups) {
			const selectedOptions = state.output.webToolGroups[field.id];
			if (selectedOptions.length === 0) {
				errors.push(
					`You need to select at least 1 option for the field '${field.label}'`
				);
			}
		}

		return { validationErrors: errors, updatedState };
	}, [
		derivedState.selectedDataFieldDimensions,
		derivedState.selectedFieldDimensions,
		setDeepState,
		state,
	]);

	const moveToBackground = useCallback(async () => {
		if (!activeReportId.current) return;

		if (activeReportTimeout.current) {
			window.clearTimeout(activeReportTimeout.current);
			activeReportTimeout.current = null;
		}

		await WebToolAPI.moveReportToBackground(activeReportId.current);
		setStatus(WorksheetPreviewStatus.ReportMovedToBackground);
		setIsMoveToBackgroundAvailable(false);
	}, []);

	const runWorksheet = useCallback(
		async (format: WorksheetExportFormat = 'tsv') => {
			const { validationErrors, updatedState } = validateWorksheet();

			if (validationErrors.length > 0) {
				createToast(ToastType.ERROR, {
					message: 'Validation failed',
					hint: validationErrors,
				});

				return;
			}

			// If report running is blocked, don't run the report
			if (isReportRunningBlocked) {
				createToast(ToastType.WARNING, {
					message: 'Another report run has just started',
					hint: 'Please wait a moment before running this report again',
				});

				return;
			}

			setIsReportRunningBlocked(true);

			if (status === WorksheetPreviewStatus.ReportRunning) {
				// Clear the timeout that's polling for updates
				if (activeReportTimeout.current) {
					window.clearTimeout(activeReportTimeout.current);
					activeReportTimeout.current = null;
				}

				setIsMoveToBackgroundAvailable(false);
			}

			setLastExportFormat(format);
			setStatus(WorksheetPreviewStatus.ReportRunning);
			setPreviewData(undefined);

			setIsParametersPanelOpen(false);
			setIsOutputPanelOpen(false);
			setIsLayoutPanelOpen(false);

			let startTime = new Date();
			const response = await WebToolAPI.updateWorksheetWithAction(
				worksheet.id,
				updatedState,
				format === 'screen' ? WorksheetAction.RUN : WorksheetAction.EXPORT,
				format
			);

			if (!schedule) {
				setWorksheetHasChanges(false);
			}

			setLastRunState(updatedState);

			// From here, report running is no longer blocked
			setIsReportRunningBlocked(false);

			if (!('reportId' in response)) {
				displayValidationMessages(response);
				setStatus(WorksheetPreviewStatus.Error);
				return;
			}

			if (!response.reportId) throw new Error('ReportId not returned');
			activeReportId.current = response.reportId;

			const taskInterval = async () => {
				if (!response.reportId || activeReportId.current !== response.reportId)
					return;

				const { status: exportStatus, queryStatus } =
					await WebToolAPI.getWorksheetReportStatus(response.reportId);

				if (
					statusRef.current === WorksheetPreviewStatus.ReportMovedToBackground
				)
					return;

				switch (exportStatus) {
					case 'IN-PROGRESS':
						{
							const timeElapsed = new Date().getTime() - startTime.getTime();

							// Only do this once
							if (timeElapsed > 10 * 1000) {
								setIsMoveToBackgroundAvailable(true);
							}

							const timeoutId = window.setTimeout(() => {
								taskInterval().catch(catchWithError);
							}, 1000);

							activeReportTimeout.current = timeoutId;
						}
						break;
					case 'FAILED':
						setStatus(WorksheetPreviewStatus.Error);
						setQueryStatus(queryStatus);
						setIsMoveToBackgroundAvailable(false);
						break;
					case 'CANCELLED':
						setStatus(WorksheetPreviewStatus.Hidden);
						setIsMoveToBackgroundAvailable(false);
						break;
					case 'SUCCESS':
						setIsMoveToBackgroundAvailable(false);
						if (format === 'screen') {
							setQueryStatus(queryStatus);
							await previewWorksheet(response.reportId, state.layout.sorting);
						} else {
							await downloadExport(response.reportId);
						}
						setStatus(WorksheetPreviewStatus.ReportCompleted);
						break;
				}
			};

			taskInterval().catch(catchWithError);
		},
		[validateWorksheet, downloadExport, previewWorksheet, worksheet.id, status]
	);

	const contextValue: WorksheetContextValue = useMemo(
		() => ({
			worksheet,
			weightBreakGroups,
			queryStatus,
			setQueryStatus,
			state,
			setState: onStateChange,
			setDeepState,
			derivedState,
			isReportOptionsOpen,
			setIsReportOptionsOpen,
			isMoveToBackgroundAvailable,
			setIsMoveToBackgroundAvailable,
			lastExportFormat,
			isParametersPanelOpen,
			setIsParametersPanelOpen,
			isOutputPanelOpen,
			setIsOutputPanelOpen,
			isLayoutPanelOpen,
			setIsLayoutPanelOpen,
			saveWorksheet,
			previewWorksheet,
			runWorksheet,
			status,
			validateWorksheet,
			moveToBackground,
			previewData,
			setPreviewData,
			isScheduleModalOpen,
			setIsScheduleModalOpen,
			worksheetHasChanges,
			lastRunState,
			setLastRunState,
			hasSortingChangedSinceLastRun,
			hasLayoutCompositionChangedSinceLastRun,
			schedule,
			setSchedule,
			isShareModalOpen,
			setIsShareModalOpen,
			isReportRunningBlocked,
		}),
		[
			worksheet,
			weightBreakGroups,
			queryStatus,
			setQueryStatus,
			state,
			onStateChange,
			setDeepState,
			derivedState,
			isReportOptionsOpen,
			setIsReportOptionsOpen,
			lastExportFormat,
			isParametersPanelOpen,
			setIsParametersPanelOpen,
			isMoveToBackgroundAvailable,
			setIsMoveToBackgroundAvailable,
			isOutputPanelOpen,
			setIsOutputPanelOpen,
			isLayoutPanelOpen,
			setIsLayoutPanelOpen,
			saveWorksheet,
			previewWorksheet,
			runWorksheet,
			status,
			validateWorksheet,
			moveToBackground,
			previewData,
			setPreviewData,
			isScheduleModalOpen,
			setIsScheduleModalOpen,
			lastRunState,
			setLastRunState,
			worksheetHasChanges,
			hasSortingChangedSinceLastRun,
			hasLayoutCompositionChangedSinceLastRun,
			schedule,
			setSchedule,
			isShareModalOpen,
			setIsShareModalOpen,
			isReportRunningBlocked,
		]
	);

	return (
		<WorksheetContext.Provider value={contextValue}>
			{contextValue ? children : null}
		</WorksheetContext.Provider>
	);
};

export const useWorksheetContext = () => useContext(WorksheetContext);

export default WorksheetContext;

/**
 * Create an object of disabled optionGroups based on the current list of parameters / fields selected
 */
function deriveDisabledGroupOptions(
	worksheet: Worksheet,
	state: WorksheetState,
	setDeepState: SetDeepState,
	selectedFields: WorksheetConfigField[]
) {
	const disabledGroupOptionsMap: DisabledGroupOptionsMap = {};
	for (const restriction of worksheet.config.restrictions) {
		if (
			restriction.parameters.some((p) =>
				hasValuesForParameter(state.parameters, p)
			) ||
			selectedFields.some((s) => restriction.fields.includes(s.id))
		) {
			for (const disabledGroup of restriction.disabledFieldGroupOptions) {
				disabledGroupOptionsMap[disabledGroup.id] = disabledGroup.options;
				let disabledOptions = calculateDisabledGroupOptions(
					disabledGroupOptionsMap,
					disabledGroup
				);
				disabledGroupOptionsMap[disabledGroup.id] = disabledOptions;
			}
		}
	}

	const groupDetails = Object.keys(disabledGroupOptionsMap)
		.map((key) => {
			const group = worksheet.config.optionGroups.find((w) => w.id === key);
			return {
				key,
				groupName: group?.name ?? '',
				groupOptions: group?.options ?? [],
				selectedOptions: state.output.fieldOptionGroups[key],
				disabledptions: disabledGroupOptionsMap[key],
			};
		})
		.filter((v) => v.disabledptions);

	let warnUserOfChanges = true;

	for (const groupDetail of groupDetails) {
		let newSelectedOptions = groupDetail.selectedOptions.filter(
			(optionId) => !groupDetail.disabledptions.includes(optionId)
		);

		if (newSelectedOptions.length === 0) {
			// If there are no selected options, get the first available option and set selectedOptions to that
			newSelectedOptions = groupDetail.groupOptions
				.map((option) => option.id)
				.filter((id) => !groupDetail.disabledptions.includes(id))
				.slice(0, 1);
		}

		warnUserOfChanges = updateReportOptions(
			newSelectedOptions,
			groupDetail,
			warnUserOfChanges,
			setDeepState
		);
	}

	return disabledGroupOptionsMap;
}

function updateReportOptions(
	newSelectedOptions: string[],
	groupDetail: {
		key: string;
		groupName: string;
		groupOptions: {
			id: string;
			label: string;
			sortOrder: number;
			defaultSelected: boolean;
		}[];
		selectedOptions: string[];
		disabledptions: string[];
	},
	warnUserOfChanges: boolean,
	setDeepState: SetDeepState
) {
	if (!isPrimitiveArrayEqual(newSelectedOptions, groupDetail.selectedOptions)) {
		if (warnUserOfChanges) {
			warnUserOfChanges = false;
			createToast(
				ToastType.WARNING,
				{
					message: 'Report Options have been automatically updated.',
				},
				{ id: 'report-options-updated' }
			);
		}
		setDeepState(
			`output.fieldOptionGroups.${groupDetail.key}`,
			newSelectedOptions
		);
	}
	return warnUserOfChanges;
}

function calculateDisabledGroupOptions(
	disabledGroupOptionsMap: DisabledGroupOptionsMap,
	disabledGroup: { id: string; options: string[] }
) {
	let disabledOptions = disabledGroupOptionsMap[disabledGroup.id];

	if (disabledOptions) {
		// If there are already disabled segments, we need to merge them to always returned the disabled items
		disabledOptions = distinct(disabledOptions.concat(disabledGroup.options));
	} else {
		disabledOptions = disabledGroup.options;
	}
	return disabledOptions;
}
