import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { addDays, format, startOfWeek } from 'date-fns';
import {
	Calendar,
	CalendarClock,
	Download,
	Package,
	Plane,
} from 'lucide-react';
import { useCallback, useState } from 'react';
import {
	Link,
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
	useRevalidator,
} from 'react-router-dom';
import AttachmentLink from 'ui/components/AttachmentLink';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button/Button';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import WebToolStatusIndicator from 'ui/components/WebToolStatusIndicator/WebToolStatusIndicator';
import * as genericColumns from 'utils/columns/genericColumns';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import {
	abstractUtcToLocal,
	getDayOffsetForLocalHour,
	getUTCOffsetInHours,
	LEAP_YEAR,
	modDaysOfWeek,
	modHours,
	utcHourToLocal,
} from 'utils/helpers/timezone';
import useRootLoader from 'utils/hooks/useRootLoader';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import ReportHubAPI, {
	ScheduledWebToolReport,
	WebToolReportRunResult,
} from '../../api/ReportHubAPI';
import WorksheetScheduleHistoryModal from '../../components/WorksheetScheduleModal/WorksheetScheduleHIstoryModal';
import WorksheetScheduleModal, {
	WorksheetScheduleModalMode,
} from '../../components/WorksheetScheduleModal/WorksheetScheduleModal';
import { rootLoader } from '../../main';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const webToolReports = await ReportHubAPI.getScheduledWebToolReports(
		new URL(request.url).searchParams
	);

	return {
		webToolReports,
	};
};

export const loadHistory = async (id: string) => {
	const data = await ReportHubAPI.getScheduledWebToolHistory(id);

	return data.history;
};

const ReportHubWebToolScheduledActions = ({
	report,
}: {
	report: ScheduledWebToolReport;
}) => {
	const revalidator = useRevalidator();

	let defaultHistory: WebToolReportRunResult[] = [];
	let [historyItems, setHistory] = useState(defaultHistory);
	const [isHistoryOpen, setIsHistoryOpen] = useState(false);
	const [isScheduleOpen, setIsScheduleOpen] = useState(false);

	return (
		<>
			<Flex gap={8}>
				<Button
					size="small"
					variant="secondary"
					icon={Calendar}
					onClick={async () => {
						const data = await loadHistory(report.id);

						setHistory(data);
						setIsHistoryOpen(true);
					}}
					data-testid="viewHistoryButton"
				>
					{'View History'}
				</Button>
				<Button
					size="small"
					variant="secondary"
					icon={CalendarClock}
					onClick={() => setIsScheduleOpen(true)}
				>
					{'Edit Schedule'}
				</Button>
			</Flex>
			<WorksheetScheduleModal
				initialSchedule={report.schedule}
				isOpen={isScheduleOpen}
				onClose={() => setIsScheduleOpen(false)}
				worksheetId={report.id}
				mode={WorksheetScheduleModalMode.EDIT}
				onAfterSave={(schedule) => {
					setIsScheduleOpen(false);
					revalidator.revalidate();
				}}
				onAfterDelete={() => {
					setIsScheduleOpen(false);
					revalidator.revalidate();
				}}
			/>
			<WorksheetScheduleHistoryModal
				isOpen={isHistoryOpen}
				onClose={() => setIsHistoryOpen(false)}
				worksheetId={report.id}
				history={historyItems}
			/>
		</>
	);
};

export function ReportHubWebToolScheduledListPage() {
	const { webToolReports } = useLoaderData() as LoaderData<typeof loader>;
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;
	const location = useLocation();

	const showSubscriptionColumn =
		(rootLoaderData?.userProfile.reportCounts.totalWebTool ?? 0) > 1;

	const [selectedReportIdsForExport, setSelectedReportIdsForExport] = useState<
		string[]
	>([]);

	// Creating a key for the table so the checkbox selection is persisted across pages but not when new search filters are applied
	const searchParams = new URLSearchParams(location.search);
	searchParams.delete('page');

	const scheduleColumnHelper = createColumnHelper<ScheduledWebToolReport>();

	const scheduleColumns = [
		genericColumns.selectColumn as ColumnDef<ScheduledWebToolReport, void>,
		scheduleColumnHelper.accessor('reportName', {
			header: 'Report Name',
			cell: (info) => {
				const Icon =
					info.row.original.reportDataType === 'awb' ? Package : Plane;

				return (
					<Link to={`/webtool/${info.row.original.id}`}>
						<Flex alignItems="center" gap={4}>
							<Icon size={14} />
							{info.getValue()}
						</Flex>
					</Link>
				);
			},
		}),
		scheduleColumnHelper.accessor('lastExecution', {
			header: 'Last Execution Status',
			cell: ({ row }) => {
				if (!row.original.lastExecution) return '–';

				return (
					<WebToolStatusIndicator
						intent={row.original.lastExecution.status.value}
						label={row.original.lastExecution.status.label}
					/>
				);
			},
			id: 'lastExecutionStatus',
		}),
		scheduleColumnHelper.accessor('lastExecution', {
			header: 'Last Execution',
			cell: (info) => (
				<DateFragment includeTime={true} date={info.getValue()?.executedAt} />
			),
			id: 'lastExecution',
		}),
		scheduleColumnHelper.accessor('nextExecution', {
			header: 'Next Execution',
			cell: (info) => {
				if (info.row.original.suspended) {
					return (
						<BulletIndicator
							intent={'warning'}
							label="Suspended"
							toolTip={info.row.original.suspendedMessage ?? '–'}
						/>
					);
				}
				return (
					<DateFragment
						includeTime={true}
						date={info.getValue()}
						emptyText="On new data"
					/>
				);
			},
			id: 'nextExecution',
		}),
		scheduleColumnHelper.accessor('schedule', {
			header: 'Frequency',
			cell: (info) => {
				const schedule = info.getValue();
				const currentDate = new Date();

				switch (schedule.frequency) {
					case 'weekly': {
						const utcOffset = getUTCOffsetInHours();
						const dayOffset = getDayOffsetForLocalHour(
							modHours(schedule.time - utcOffset)
						);

						const localDayOfWeek = modDaysOfWeek(
							schedule.dayOfWeek - dayOffset
						);

						const thisWeek = startOfWeek(currentDate);
						const dayOfWeekDate = addDays(thisWeek, localDayOfWeek);
						const formattedDayOfWeek = format(dayOfWeekDate, 'EEEE');

						return `Weekly (${formattedDayOfWeek})`;
					}
					case 'monthly': {
						const localData = abstractUtcToLocal({
							date: schedule.dayOfMonth,
							month: currentDate.getMonth(),
							hours: schedule.time,
						});

						const formattedFrequency = format(
							new Date(LEAP_YEAR, currentDate.getMonth(), localData.date),
							'do'
						);

						return `Monthly (${formattedFrequency})`;
					}
					case 'yearly': {
						const { date: localDay, month: localMonth } = abstractUtcToLocal({
							month: schedule.monthAndDay.month,
							date: schedule.monthAndDay.day,
							hours: schedule.time,
						});

						const formattedFrequency = format(
							new Date(LEAP_YEAR, localMonth - 1, localDay),
							'MMM do'
						);

						return `Yearly (${formattedFrequency})`;
					}
					default:
						return 'On new data';
				}
			},
			id: 'frequency',
		}),
		scheduleColumnHelper.display({
			header: 'Time',
			cell: (info) => {
				const schedule = info.row.original.schedule;

				switch (schedule.frequency) {
					case 'monthly':
					case 'weekly':
					case 'yearly': {
						// This value might be a decimal
						const localHour = utcHourToLocal(schedule.time);

						// So we need to convert it properly
						const hour = Math.floor(localHour).toString().padStart(2, '0');
						const minutes = ((localHour % 1) * 60).toString().padStart(2, '0');

						return `${hour}:${minutes}`;
					}
					default:
						return '–';
				}
			},
			id: 'time',
		}),
		scheduleColumnHelper.accessor('schedule.activeFrom', {
			header: 'Active from',
			cell: (row) => (
				<>
					<DateFragment date={row.getValue()} />
				</>
			),
			id: 'activeFrom',
		}),
		scheduleColumnHelper.display({
			header: 'Active until',
			cell: (row) => (
				<>
					<DateFragment date={row.row.original.schedule.activeTo} />
				</>
			),
			id: 'activeUntil',
		}),
		scheduleColumnHelper.accessor('lastExecution', {
			header: 'Last Attachment',
			cell: (info) => {
				if (
					!info.row.original.lastExecution ||
					info.row.original.lastExecution.filename === ''
				) {
					return '–';
				}

				return (
					<AttachmentLink
						url={`/report-hub/webtool-reports/${info.row.original.lastExecution.id}/download`}
						target="_blank"
						name={info.row.original.lastExecution.filename}
						size={info.row.original.lastExecution.fileSizeInBytes}
					/>
				);
			},
			id: 'lastAttachment',
		}),
		scheduleColumnHelper.display({
			id: 'actions',
			header: '',
			meta: {
				shrink: true,
			},
			cell: (info) => {
				return <ReportHubWebToolScheduledActions report={info.row.original} />;
			},
		}),
	];

	if (showSubscriptionColumn) {
		let subscriptionColumn = scheduleColumnHelper.accessor('subscriptionName', {
			header: 'Subscription Name',
			cell: (info) => info.getValue(),
			id: 'subscriptionName',
		});

		scheduleColumns.splice(2, 0, subscriptionColumn);
	}

	const handleRowSelection = useCallback(
		(rowSelection: Record<string, boolean>) => {
			const selectedReportIds = Object.keys(rowSelection);
			setSelectedReportIdsForExport(selectedReportIds);
		},
		[setSelectedReportIdsForExport]
	);

	const exportData = () => {
		window.open(
			`/report-hub/scheduled-reports/download/${selectedReportIdsForExport.join(
				','
			)}`,
			'_blank'
		);

		window.focus();
	};

	const exportAll = () => {
		window.open('/report-hub/scheduled-reports/download/all', '_blank');
		window.focus();
	};

	return (
		<div className="content">
			<Flex direction="column" gap={48}>
				<Flex direction="column" gap={24}>
					<PageHeader title="Scheduled Reports">
						<Button
							variant="secondary"
							onClick={exportData}
							isDisabled={selectedReportIdsForExport.length === 0}
							icon={Download}
						>
							Export selected{' '}
							{selectedReportIdsForExport.length > 0
								? `(${selectedReportIdsForExport.length})`
								: ''}
						</Button>
						<Button variant="secondary" icon={Download} onClick={exportAll}>
							Export all
						</Button>
					</PageHeader>

					<Table
						identifierKey="id"
						key={'scheduled-reports'}
						columns={scheduleColumns}
						data={webToolReports.reports.items}
						onRowSelectionChange={handleRowSelection}
						tableOptions={{
							enableRowSelection: (row) => {
								return row.original.lastExecution?.status.value === 'success';
							},
						}}
					/>

					{webToolReports.reports.totalCount > 0 && (
						<Pagination
							baseUrl={new URL(window.location.href)}
							page={webToolReports.reports.page}
							pageParameterName="page"
							pageSize={webToolReports.reports.pageSize}
							itemCount={webToolReports.reports.totalCount}
						/>
					)}
				</Flex>
			</Flex>
		</div>
	);
}

export const REPORT_HUB_WEB_TOOL_SCHEDULED_LIST_ROUTE: DecentralizedRouteProps =
	{
		loader: loader,
		element: <ReportHubWebToolScheduledListPage />,
	};
