import classNames from 'classnames';
import {
	add,
	addDays,
	endOfWeek,
	format,
	getWeek,
	startOfWeek,
	startOfYear,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
	ArrowUpDown,
	Calendar,
	CalendarDays,
	CalendarRange,
} from 'lucide-react';
import { useMemo } from 'react';
import Flex from 'ui/components/Flex/Flex';
import FormField from 'ui/components/FormField/FormField';
import Grid from 'ui/components/Grid/Grid';
import NumberField from 'ui/components/NumberField';
import PillSelection from 'ui/components/PillSelection/PillSelection';
import Select from 'ui/components/Select/Select';
import Tooltip from 'ui/components/Tooltip';
import TooltipContent from 'ui/components/Tooltip/TooltipContent';
import TooltipTrigger from 'ui/components/Tooltip/TooltipTrigger';
import useFormReset from 'ui/components/ValidatedForm/useFormReset';
import WebToolDateField from 'ui/components/WebToolDateField';
import {
	WEBTOOL_DATE_FORMATS,
	WEEK_PICKER_LOCALE,
	WEEK_START,
} from 'ui/components/WebToolDatePicker/WebToolDatePicker';
import {
	DateRangeCompare,
	DateRangeFormats,
	DateRangeRelative,
	DateUnit,
} from 'utils/api/WebToolAPI';
import { useWorksheetContext } from './WorksheetContext';

type WorksheetDateRangeFieldProps = {};

const WorksheetDateRangeFieldPickerPreview = (props: {
	periodName: string;
}) => (
	<>
		{props.periodName === 'year-to-date' ? (
			<>Year-to-Date (YTD)</>
		) : (
			<>
				Last <b>[n]</b> {props.periodName}s of data
			</>
		)}
	</>
);

type DatePeriodSelectorProps = {
	period: DateUnit;
	onChange: (period: DateUnit) => void;
	dateRangeFormats: DateRangeFormats;
};

type DateUnitOption = {
	value: DateUnit;
	icon: JSX.Element;
	text: string;
	tooltip: JSX.Element;
};

const DatePeriodSelector = ({
	period,
	onChange,
	dateRangeFormats,
}: DatePeriodSelectorProps) => {
	const pickerOptions = useMemo(() => {
		return [
			dateRangeFormats.day &&
				({
					value: 'day',
					icon: <CalendarDays size={14} />,
					text: 'Day',
					tooltip: <>Select date range by day</>,
				} satisfies DateUnitOption),
			dateRangeFormats.week &&
				({
					value: 'week',
					icon: <CalendarRange size={14} />,
					text: 'Week',
					tooltip: (
						<>
							A week is defined as starting on Monday and ending on Sunday.
							<br /> The first week of the year is defined to start on the first
							Monday of the year
						</>
					),
				} satisfies DateUnitOption),
			dateRangeFormats.month &&
				({
					value: 'month',
					icon: <Calendar size={14} />,
					text: 'Month',
					tooltip: <>Select date range by month</>,
				} satisfies DateUnitOption),
		].filter((option): option is DateUnitOption => option !== false);
	}, [dateRangeFormats]);

	if (pickerOptions.length < 2 && period !== 'week') {
		return null;
	}

	return (
		<Flex justifyContent="end" gap={8}>
			{pickerOptions.map((option) => (
				<Tooltip key={option.value}>
					<TooltipContent>{option.tooltip}</TooltipContent>
					<TooltipTrigger>
						<button
							type="button"
							className={classNames('layout-builder__section-action', {
								'layout-builder__section-action--active':
									option.value === period,
							})}
							onClick={(e) => {
								/* stopPropagation to stop the label receiving the click and opening the calendar  */
								e.stopPropagation();
								onChange(option.value);
							}}
						>
							{option.icon}
							{option.text}
						</button>
					</TooltipTrigger>
				</Tooltip>
			))}
		</Flex>
	);
};

const WorksheetDateRangeField = ({}: WorksheetDateRangeFieldProps) => {
	const { worksheet, state, setDeepState } = useWorksheetContext();

	const makeRelativePeriodOption = (
		periodName: 'day' | 'week' | 'month' | 'year' | 'year-to-date'
	) => ({
		value: periodName,
		comp: <WorksheetDateRangeFieldPickerPreview periodName={periodName} />,
	});

	const handleSwitchCompareDates = () => {
		setDeepState('parameters.dateRange.compare', {
			startDate: state.parameters.dateRange.compare.compareStartDate,
			endDate: state.parameters.dateRange.compare.compareEndDate,
			compareStartDate: state.parameters.dateRange.compare.startDate,
			compareEndDate: state.parameters.dateRange.compare.endDate,
			unit: state.parameters.dateRange.compare.unit,
		});
	};

	const handleComparePeriodChange = (unit: DateUnit) => {
		setDeepState('parameters.dateRange.compare', {
			...state.parameters.dateRange.compare,
			unit: unit,
		});
	};
	const handleAbsolutePeriodChange = (unit: DateUnit) => {
		setDeepState('parameters.dateRange.absolute', {
			...state.parameters.dateRange.absolute,
			unit: unit,
		});
	};

	const compareDiffPeriodLengthWarning = useMemo(() => {
		return warnWhenCompareLengthDiffers(state.parameters.dateRange.compare);
	}, [state.parameters.dateRange.compare]);

	const relativePeriodOptions = useMemo(
		() =>
			[
				worksheet.config.parameters.dateRange.formats.day &&
					makeRelativePeriodOption('day'),
				worksheet.config.parameters.dateRange.formats.week &&
					makeRelativePeriodOption('week'),
				worksheet.config.parameters.dateRange.formats.month &&
					makeRelativePeriodOption('month'),
				worksheet.config.parameters.dateRange.formats.year &&
					makeRelativePeriodOption('year'),
				worksheet.config.parameters.dateRange.formats.yearToDate &&
					makeRelativePeriodOption('year-to-date'),
			].filter(
				(option): option is ReturnType<typeof makeRelativePeriodOption> =>
					option !== false
			),
		[]
	);

	useFormReset(() => {
		const defaultFromDate = worksheet.config.parameters.defaultFromDate;
		const defaultToDate = worksheet.config.parameters.defaultToDate;
		const defaultDateUnit =
			worksheet.config.parameters.defaultDateUnit ?? 'month';

		setDeepState('parameters.dateRange', {
			selectedType: 'absolute',
			absolute: {
				startDate: defaultFromDate,
				endDate: defaultToDate,
				unit: defaultDateUnit,
			},
			relative: {
				periodCount: 1,
				period: defaultDateUnit,
			},
			compare: {
				startDate: defaultFromDate,
				endDate: defaultToDate,
				compareStartDate: defaultFromDate,
				compareEndDate: defaultToDate,
				unit: defaultDateUnit,
			},
		});
	});

	return (
		<Flex direction="column" gap={16}>
			<Flex gap={4} justifyContent="space-between">
				<b>Date Range</b>
				<Flex direction="column" gap={4} alignItems="end">
					<PillSelection
						options={[
							{ id: 'absolute', label: 'Absolute' },
							{ id: 'relative', label: 'Relative' },
							{ id: 'compare', label: 'Compare' },
						]}
						selectedOptions={[state.parameters.dateRange.selectedType]}
						onOptionSelected={(option) => {
							setDeepState('parameters.dateRange.selectedType', option);
						}}
					/>
				</Flex>
			</Flex>

			{state.parameters.dateRange.selectedType === 'absolute' && (
				<Grid gap="var(--space-1)" columns={1}>
					<Grid gap="4" columns={2}>
						<WebToolDateField
							minDate={worksheet.config.parameters.minimumDate}
							maxDate={worksheet.config.parameters.maximumDate}
							name="from"
							label="From"
							placeholder="Start Date"
							value={state.parameters.dateRange.absolute.startDate}
							pickerPeriod={state.parameters.dateRange.absolute.unit}
							onChange={(value) => {
								if (value) {
									setDeepState(
										'parameters.dateRange.absolute.startDate',
										value
									);
								}
							}}
						/>
						<WebToolDateField
							minDate={worksheet.config.parameters.minimumDate}
							maxDate={worksheet.config.parameters.maximumDate}
							name="to"
							label="To"
							placeholder="End Date"
							monthPickerUseLastDay
							value={state.parameters.dateRange.absolute.endDate}
							pickerPeriod={state.parameters.dateRange.absolute.unit}
							onChange={(value) => {
								if (value) {
									setDeepState('parameters.dateRange.absolute.endDate', value);
								}
							}}
							secondaryLabel={
								<DatePeriodSelector
									period={state.parameters.dateRange.absolute.unit}
									onChange={handleAbsolutePeriodChange}
									dateRangeFormats={
										worksheet.config.parameters.dateRange.formats
									}
								/>
							}
						/>
					</Grid>
					{state.parameters.dateRange.absolute.unit === 'week' && (
						<Grid>
							{describeWeekSelection(
								state.parameters.dateRange.absolute.startDate,
								state.parameters.dateRange.absolute.endDate,
								true
							)}
						</Grid>
					)}
				</Grid>
			)}

			{state.parameters.dateRange.selectedType === 'relative' && (
				<>
					<FormField label="Period">
						<Select<(typeof relativePeriodOptions)[0]>
							onOptionSelected={(option) => {
								if (option) {
									setDeepState(
										'parameters.dateRange.relative.period',
										option.value
									);
								}
							}}
							selectedOption={
								relativePeriodOptions.find(
									(option) =>
										option.value === state.parameters.dateRange.relative.period
								) ?? null
							}
							name="relativeDateFormat"
							options={relativePeriodOptions}
							identifierKey="value"
							contentSource={(i) => i.comp}
							isClearable={false}
							initialValue={relativePeriodOptions[1]}
						/>
					</FormField>

					{state.parameters.dateRange.relative.period !== 'year-to-date' && (
						<NumberField
							name="relativeDateFormatCount"
							label={
								state.parameters.dateRange.relative.period
									.charAt(0)
									.toUpperCase() +
								state.parameters.dateRange.relative.period.slice(1)
							}
							value={state.parameters.dateRange.relative.periodCount}
							onChange={(value) => {
								setDeepState(
									'parameters.dateRange.relative.periodCount',
									value ?? 0
								);
							}}
							description={relativePeriodDescription(
								state.parameters.dateRange.relative,
								worksheet.config.parameters.defaultToDate,
								worksheet.config.parameters.defaultDateUnit
							)}
						/>
					)}
				</>
			)}

			{state.parameters.dateRange.selectedType === 'compare' && (
				<>
					<Grid gap="var(--space-1)">
						<Grid gap="4" columns={2}>
							<WebToolDateField
								name="from"
								label="From"
								placeholder="Start Date"
								value={state.parameters.dateRange.compare.startDate}
								pickerPeriod={state.parameters.dateRange.compare.unit}
								minDate={worksheet.config.parameters.minimumDate}
								maxDate={worksheet.config.parameters.maximumDate}
								onChange={(value) => {
									if (value) {
										setDeepState(
											'parameters.dateRange.compare.startDate',
											value
										);
									}
								}}
							/>
							<WebToolDateField
								name="to"
								label="To"
								placeholder="End Date"
								value={state.parameters.dateRange.compare.endDate}
								pickerPeriod={state.parameters.dateRange.compare.unit}
								minDate={worksheet.config.parameters.minimumDate}
								maxDate={worksheet.config.parameters.maximumDate}
								onChange={(value) => {
									if (value) {
										setDeepState('parameters.dateRange.compare.endDate', value);
									}
								}}
								secondaryLabel={
									<DatePeriodSelector
										period={state.parameters.dateRange.compare.unit}
										onChange={handleComparePeriodChange}
										dateRangeFormats={
											worksheet.config.parameters.dateRange.formats
										}
									/>
								}
							/>
						</Grid>
						{state.parameters.dateRange.compare.unit === 'week' && (
							<Grid>
								{describeWeekSelection(
									state.parameters.dateRange.compare.startDate,
									state.parameters.dateRange.compare.endDate
								)}
							</Grid>
						)}
					</Grid>

					<Grid gap="var(--space-1)">
						<Grid gap="4" columns={2}>
							<WebToolDateField
								name="compareFrom"
								label="Compare From"
								placeholder="Comparison Start"
								value={state.parameters.dateRange.compare.compareStartDate}
								pickerPeriod={state.parameters.dateRange.compare.unit}
								minDate={worksheet.config.parameters.minimumDate}
								maxDate={worksheet.config.parameters.maximumDate}
								warnings={
									compareDiffPeriodLengthWarning
										? [compareDiffPeriodLengthWarning]
										: undefined
								}
								onChange={(value) => {
									if (value) {
										setDeepState(
											'parameters.dateRange.compare.compareStartDate',
											value
										);
									}
								}}
							/>
							<WebToolDateField
								name="compareTo"
								label="Compare To"
								secondaryLabel={
									<button
										className="layout-builder__section-action"
										type="button"
										onClick={handleSwitchCompareDates}
									>
										<ArrowUpDown size={14} />
										Switch
									</button>
								}
								placeholder="Comparison End"
								value={state.parameters.dateRange.compare.compareEndDate}
								pickerPeriod={state.parameters.dateRange.compare.unit}
								minDate={worksheet.config.parameters.minimumDate}
								maxDate={worksheet.config.parameters.maximumDate}
								onChange={(value) => {
									if (value) {
										setDeepState(
											'parameters.dateRange.compare.compareEndDate',
											value
										);
									}
								}}
							/>
						</Grid>
						{state.parameters.dateRange.compare.unit === 'week' && (
							<Grid>
								{describeWeekSelection(
									state.parameters.dateRange.compare.compareStartDate,
									state.parameters.dateRange.compare.compareEndDate,
									true
								)}
							</Grid>
						)}
					</Grid>
				</>
			)}
		</Flex>
	);
};

// Difference helpers

const getUtcDaysBetween = (date1: Date, date2: Date) => {
	// Ensure both inputs are Date objects
	if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
		throw new Error('Both arguments must be Date objects.');
	}

	// Get the UTC time values of the dates
	const utc1 = Date.UTC(
		date1.getUTCFullYear(),
		date1.getUTCMonth(),
		date1.getUTCDate()
	);
	const utc2 = Date.UTC(
		date2.getUTCFullYear(),
		date2.getUTCMonth(),
		date2.getUTCDate()
	);

	// Calculate the difference in milliseconds
	const msPerDay = 24 * 60 * 60 * 1000;
	const diffInMs = Math.abs(utc1 - utc2);

	// Calculate the number of full days
	const fullDays = Math.floor(diffInMs / msPerDay);

	return fullDays;
};

const getUtcMonthsBetween = (date1: Date, date2: Date) => {
	// Ensure both inputs are Date objects
	if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
		throw new Error('Both arguments must be Date objects.');
	}

	// Get the UTC year and month values of the dates
	const year1 = date1.getUTCFullYear();
	const month1 = date1.getUTCMonth();
	const year2 = date2.getUTCFullYear();
	const month2 = date2.getUTCMonth();

	// Calculate the difference in months
	const yearDiff = year2 - year1;
	const monthDiff = month2 - month1;

	// Calculate the total number of months
	const totalMonths = yearDiff * 12 + monthDiff;

	// Get the days part of the dates
	const day1 = date1.getUTCDate();
	const day2 = date2.getUTCDate();

	// Adjust the total months if the days part shows an incomplete month
	if (day2 < day1) {
		return totalMonths - 1;
	} else {
		return totalMonths;
	}
};

export function warnWhenCompareLengthDiffers(
	compare: DateRangeCompare
): string | undefined {
	const fromArgs = [compare.startDate, compare.endDate] as const;
	const compareArgs = [
		compare.compareStartDate,
		compare.compareEndDate,
	] as const;

	switch (compare.unit) {
		case 'day': {
			const fromResult = getUtcDaysBetween(...fromArgs);
			const compareResult = getUtcDaysBetween(...compareArgs);

			return fromResult !== compareResult
				? 'The number of days compared does not match.'
				: undefined;
		}

		case 'week': {
			const diffOptions = { locale: WEEK_PICKER_LOCALE };
			const fromArgsStartOfWeek = fromArgs.map((date) =>
				startOfWeek(utcToZonedTime(date, 'UTC'), diffOptions)
			) as [Date, Date];
			const compareArgsStartOfWeek = compareArgs.map((date) =>
				startOfWeek(utcToZonedTime(date, 'UTC'), diffOptions)
			) as [Date, Date];

			const fromResult = Math.floor(
				getUtcDaysBetween(...fromArgsStartOfWeek) / 7
			);
			const compareResult = Math.floor(
				getUtcDaysBetween(...compareArgsStartOfWeek) / 7
			);

			return fromResult !== compareResult
				? 'The number of weeks compared does not match.'
				: undefined;
		}

		default: {
			const fromResult = getUtcMonthsBetween(...fromArgs);
			const compareResult = getUtcMonthsBetween(...compareArgs);

			return fromResult !== compareResult
				? 'The number of months compared does not match.'
				: undefined;
		}
	}
}

function describeWeekSelection(
	from: Date,
	to: Date,
	includeRefLink?: boolean
): JSX.Element {
	const fromStart = startOfWeek(utcToZonedTime(from, 'UTC'), WEEK_START);
	const toEnd = endOfWeek(utcToZonedTime(to, 'UTC'), WEEK_START);

	return (
		<div className="control__description">
			{`Date Period:  ${format(fromStart, WEBTOOL_DATE_FORMATS.day)} - ${format(
				toEnd,
				WEBTOOL_DATE_FORMATS.day
			)}`}
			{includeRefLink === true && (
				<>
					<br />
					For more info please refer to Download Week Calendar above
				</>
			)}
		</div>
	);
}

type Week53DoesNotExistBehaviour = 'use-week-52' | 'use-next-year';

function dateFromYearAndWeek(
	year: number,
	week: number,
	week53DoesNotExistBehaviour: Week53DoesNotExistBehaviour
): Date {
	const firstDayOfYear = new Date(year, 0, 1);
	// Using the first day of the year, we can calculate the first Monday of the year
	let firstMondayOfYear = addDays(
		firstDayOfYear,
		(8 - firstDayOfYear.getDay()) % 7
	);
	let yearWeekDate = addDays(firstMondayOfYear, (week - 1) * 7);
	// When week 53 does not exist the default behaviour of the above logic is to move to week 1 of the next year
	// This is not always the desired behaviour, so we provide an option to move to week 52 of the current year instead
	if (
		week53DoesNotExistBehaviour === 'use-week-52' &&
		yearWeekDate.getFullYear() > year
	) {
		yearWeekDate = addDays(firstMondayOfYear, (week - 2) * 7);
	}
	return yearWeekDate;
}

function calculateRelativeDate(
	date: Date,
	relative: DateRangeRelative,
	defaultDateUnit: DateUnit
): [Date, string] {
	const relativeChange = (relative.periodCount - 1) * -1;
	switch (relative.period) {
		case 'day': {
			return [add(date, { days: relativeChange }), WEBTOOL_DATE_FORMATS['day']];
		}
		case 'week': {
			const startOfCurrentWeek = startOfWeek(date, WEEK_START);
			const fromWeek = add(startOfCurrentWeek, {
				weeks: relativeChange,
			});
			return [fromWeek, WEBTOOL_DATE_FORMATS['day']];
		}
		case 'year': {
			const relativeYearChange = -1 * relative.periodCount;
			// For weekly web tools we have to calculate the date from the year and week
			if (defaultDateUnit === 'week') {
				// The date is provided as the end of the week (Sunday),
				// so we need to move to the start of the week
				const startOfDate = startOfWeek(date, WEEK_START);
				const relativeWeek = getWeek(startOfDate, WEEK_START);
				const relativeYear = startOfDate.getFullYear();
				const fromYear = relativeYear + relativeYearChange;
				const relativeYearWeekDate = dateFromYearAndWeek(
					fromYear,
					relativeWeek,
					'use-week-52'
				);
				// Now we are on the equivalent week X many years ago,
				// having dealt with 53 week years,
				// so add a week to avoid selecting the same week of the previous year
				const fromWeek = add(relativeYearWeekDate, {
					weeks: 1,
				});
				return [fromWeek, WEBTOOL_DATE_FORMATS['day']];
			}

			const fromYearMonth = add(date, {
				years: relativeYearChange,
				months: 1,
			});
			return [fromYearMonth, WEBTOOL_DATE_FORMATS['month']];
		}
		case 'year-to-date': {
			const fromYearMonth = startOfYear(date);
			return [fromYearMonth, WEBTOOL_DATE_FORMATS['month']];
		}
		case 'month':
		default: {
			const fromMonth = add(date, { months: relativeChange });
			return [fromMonth, WEBTOOL_DATE_FORMATS['month']];
		}
	}
}

function relativePeriodDescription(
	relative: DateRangeRelative,
	toDate: Date,
	defaultDateUnit: DateUnit
): string | undefined {
	if (relative.period !== 'year-to-date' && relative.periodCount < 1) {
		return undefined;
	}

	const [fromDate, dateFormat] = calculateRelativeDate(
		utcToZonedTime(toDate, 'UTC'),
		relative,
		defaultDateUnit
	);

	return `Selecting data from ${format(fromDate, dateFormat, {
		useAdditionalWeekYearTokens: true,
		...WEEK_START,
	})} until now.`;
}

export default WorksheetDateRangeField;
