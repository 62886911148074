import classNames from 'classnames';
import { Copy, LucideIcon } from 'lucide-react';
import { MouseEvent, useState } from 'react';
import Tooltip from '../Tooltip/Tooltip';

type CopyButtonProps = {
	size?: number;
	icon?: LucideIcon;
	className?: string;
} & (
	| {
			onClick: () => void;
	  }
	| {
			copyText: string;
	  }
);

const CopyButton = (props: CopyButtonProps) => {
	const [isCopyAnimationActive, setIsCopyAnimationActive] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const [resetCopyTimeout, setResetCopyTimeout] = useState<number | null>(null);

	const onCopyClick = () => {
		if ('onClick' in props) {
			props.onClick();
		} else {
			navigator.clipboard.writeText(props.copyText);
		}
	};

	const IconComponent = props.icon ?? Copy;

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<IconComponent
					size={props.size ?? 16}
					data-testid="copy-button"
					className={classNames(
						'copy',
						isCopyAnimationActive && 'copy--copied',
						props.className
					)}
					style={{ pointerEvents: 'auto' }}
					onAnimationEnd={() => setIsCopyAnimationActive(false)}
					onMouseLeave={() => {
						if (isCopied) {
							setIsCopied(false);
						}

						if (resetCopyTimeout) {
							clearTimeout(resetCopyTimeout);
						}
					}}
					onClick={(e: MouseEvent) => {
						e.preventDefault();
						e.stopPropagation();

						setIsCopied(true);
						setIsCopyAnimationActive(true);
						onCopyClick();

						if (resetCopyTimeout) {
							clearTimeout(resetCopyTimeout);
						}

						const timeoutId = window.setTimeout(() => {
							setIsCopied(false);
						}, 2000);

						setResetCopyTimeout(timeoutId);
					}}
				/>
			</Tooltip.Trigger>
			<Tooltip.Content>
				{isCopied ? 'Copied!' : 'Copy to clipboard'}
			</Tooltip.Content>
		</Tooltip>
	);
};

export default CopyButton;
