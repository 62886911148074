import { ENVIRONMENT_SHARED } from 'environment';
import { OptionGroup } from 'ui/components/Dropdown/types';
import API from 'utils/api/API';
import { ValidationResult } from '../helpers/codeValidation';
import { Option } from '../types/common';

export type AircraftModelType = 'aircraft-model' | 'custom-group';

export type AircraftModelSingleOption = {
	code: string;
	iataCode?: Option<string>;
	icaoCode?: Option<string>;
	name?: Option<string>;
	models?: Option<Array<string>>;
	itemType: 'aircraft-model';
};

export type AircraftModelCustomGroupOption = {
	label: string;
	code: string;
	itemType: 'custom-group';
};

export type AircraftModel =
	| AircraftModelSingleOption
	| AircraftModelCustomGroupOption;

export type GetAircraftModelsByOptionsResponse = {
	keyword: string;
	options: AircraftModel[];
	groups: null;
};

export type GetAircraftModelsByGroupResponse = {
	keyword: string;
	options: null;
	groups: OptionGroup<AircraftModel>[];
};

export type GetAircraftModelsResponse =
	| GetAircraftModelsByOptionsResponse
	| GetAircraftModelsByGroupResponse;

class AircraftModelsAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static async getAircraftModels(keyword: string, types: AircraftModelType[]) {
		const params = new URLSearchParams();
		params.append('Keyword', keyword);

		for (const type of types) {
			params.append('type', type);
		}

		return AircraftModelsAPI.get<GetAircraftModelsResponse>(
			`/aircraft-models/lookup?${params.toString()}`
		);
	}

	static async validateAircraftModelCodes(codes: string[]) {
		const params = new URLSearchParams({
			codes: codes.join(','),
		});

		return AircraftModelsAPI.get<ValidationResult<AircraftModel>>(
			`/aircraft-models/validate?${params.toString()}`
		);
	}
}

export default AircraftModelsAPI;
