import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import CapacityTypesAPI, { CapacityType } from 'utils/api/CapacityTypesAPI';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';
import Pill from '../Pill';

type SingleCapacityTypePickerProps = {
	isMulti?: false;
	initialValue?: CapacityType;
	initialValues?: never;
	onSelectedCapacityTypeChange?: (value: CapacityType | null) => void;
	onSelectedCapacityTypesChange?: never;
	selectedCapacityType?: CapacityType | null;
	selectedCapacityTypes?: never;
};

type MultiCapacityTypePickerProps = {
	isMulti: true;
	initialValue?: never;
	initialValues?: CapacityType[];
	onSelectedCapacityTypeChange?: never;
	onSelectedCapacityTypesChange?: (values: CapacityType[]) => void;
	selectedCapacityType?: never;
	selectedCapacityTypes?: CapacityType[];
};

type CapacityTypePickerProps = {
	name: string;
	label?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
} & (SingleCapacityTypePickerProps | MultiCapacityTypePickerProps);

export const CapacityTypePill = ({
	value,
	onRemove,
}: {
	value: CapacityType;
	onRemove?: () => void;
}) => {
	return <Pill name={value.name} onRemove={onRemove} />;
};

export const CapacityTypeContentSource = (capacityType: CapacityType) => {
	return capacityType.name;
};

const CapacityTypePicker = (props: CapacityTypePickerProps) => {
	const { name, label, isRequired, isDisabled } = props;

	const { errorTree } = useValidation(name);

	const searchCapacityTypes = async (searchTerm: string) => {
		const data = await CapacityTypesAPI.getCapacityTypes(searchTerm);
		return data.options;
	};

	const handleInsert = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise = CapacityTypesAPI.validateCapacityCodes(codes);

			createValidationToast(validatePromise, 'capacity type').catch(
				catchSilently
			);

			const validationResult = await validatePromise;
			return validationResult.options;
		}

		return false;
	};

	const handleCopy = async (
		capacityTypes: CapacityType[] | CapacityType | null
	) => {
		let value = '';

		if (Array.isArray(capacityTypes)) {
			value = capacityTypes.map((capacityType) => capacityType.code).join(', ');
		} else if (capacityTypes) {
			value = capacityTypes.code;
		}

		if (value) {
			navigator.clipboard.writeText(value);
		}
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<CapacityType>
					isDisabled={isDisabled}
					onSearch={searchCapacityTypes}
					identifierKey="code"
					name={name}
					onCopy={handleCopy}
					handlePasteSelection={handleInsert}
					placeholder="Search capacity types..."
					selectedOptions={props.selectedCapacityTypes}
					onSelectedOptionsChange={props.onSelectedCapacityTypesChange}
					contentSource={CapacityTypeContentSource}
					initialValues={props.initialValues}
					pillComponent={CapacityTypePill}
				/>
			) : (
				<AsyncSelect<CapacityType>
					isDisabled={isDisabled}
					onSearch={searchCapacityTypes}
					identifierKey="code"
					name={name}
					onCopy={handleCopy}
					handlePasteSelection={handleInsert}
					placeholder="Select capacity type..."
					contentSource={CapacityTypeContentSource}
					isClearable={true}
					onOptionSelected={props.onSelectedCapacityTypeChange}
					selectedOption={props.selectedCapacityType}
					initialValue={props.initialValue}
					previewSource={(o) => o.name}
				/>
			)}
		</FormField>
	);
};

export default CapacityTypePicker;
