import Pill from 'ui/components/Pill';
import { AircraftModel } from 'utils/api/AircraftModelsAPI';

type AircraftModelPickerContentProps = {
	aircraftModel: AircraftModel;
};

const AircraftModelPickerContent = ({
	aircraftModel,
}: AircraftModelPickerContentProps) => {
	if (aircraftModel.itemType === 'custom-group') {
		return <>{aircraftModel.label}</>;
	}

	const code =
		aircraftModel.iataCode === aircraftModel.icaoCode
			? aircraftModel.iataCode
			: `${aircraftModel.iataCode} / ${aircraftModel.icaoCode}`;

	const name = aircraftModel.name || 'Unknown';

	return (
		<>
			<Pill code={code ?? undefined} name={name} />
			{aircraftModel.models && aircraftModel.models.length > 0 && (
				<p>Includes {aircraftModel.models.join(', ')}</p>
			)}
		</>
	);
};

export default AircraftModelPickerContent;
