import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import ManufacturersAPI, { Manufacturer } from 'utils/api/ManufacturersAPI';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	createValidationToast,
	parseTextToCodes,
} from 'utils/helpers/codeValidation';
import { createToast } from 'utils/helpers/toast';
import Pill from '../Pill';
import { ToastType } from '../Toaster/Toast';

type SingleManufacturerPickerProps = {
	isMulti?: false;
	initialValue?: Manufacturer;
	initialValues?: never;
	onSelectedManufacturerChange?: (value: Manufacturer | null) => void;
	onSelectedManufacturersChange?: never;
	selectedManufacturer?: Manufacturer | null;
	selectedManufacturers?: never;
};

type MultiManufacturerPickerProps = {
	isMulti: true;
	initialValue?: never;
	initialValues?: Manufacturer[];
	onSelectedManufacturerChange?: never;
	onSelectedManufacturersChange?: (values: Manufacturer[]) => void;
	selectedManufacturer?: never;
	selectedManufacturers?: Manufacturer[];
};

type ManufacturerPickerProps = {
	name: string;
	label?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
} & (SingleManufacturerPickerProps | MultiManufacturerPickerProps);

const ManufacturerPicker = (props: ManufacturerPickerProps) => {
	const { name, label, isRequired, isDisabled } = props;

	const { errorTree } = useValidation(name);

	const searchManufacturers = async (searchTerm: string) => {
		const data = await ManufacturersAPI.getManufacturers(searchTerm);
		return data.options;
	};

	const handleInsert = async (text: string) => {
		const codes = parseTextToCodes(text);

		if (codes) {
			const validatePromise = ManufacturersAPI.validateManufacturerCodes(codes);

			createValidationToast(validatePromise, 'manufacturer').catch(
				catchSilently
			);

			const validationResult = await validatePromise;
			return validationResult.options;
		}

		return false;
	};

	const handleCopy = async (
		manufacturers: Manufacturer[] | Manufacturer | null
	) => {
		let value = '';
		let hasCustomGroups = false;

		if (Array.isArray(manufacturers)) {
			value = manufacturers.map((manufacturer) => manufacturer.code).join(', ');
		} else if (manufacturers) {
			value = manufacturers.code;
		}

		if (value) {
			navigator.clipboard.writeText(value);
		}

		if (hasCustomGroups) {
			createToast(ToastType.WARNING, {
				message: 'Custom groups omitted',
				hint: 'The selected custom groups have been omitted. They must be manually selected from the dropdown.',
			});
		}
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{props.isMulti ? (
				<AsyncMultiSelect<Manufacturer>
					isDisabled={isDisabled}
					onSearch={searchManufacturers}
					identifierKey="code"
					name={name}
					onCopy={handleCopy}
					handlePasteSelection={handleInsert}
					placeholder="Search manufacturers..."
					selectedOptions={props.selectedManufacturers}
					onSelectedOptionsChange={props.onSelectedManufacturersChange}
					contentSource={(o) => o.name}
					initialValues={props.initialValues}
					pillComponent={({ value, onRemove }) => (
						<Pill name={value.name} onRemove={onRemove} />
					)}
				/>
			) : (
				<AsyncSelect<Manufacturer>
					isDisabled={isDisabled}
					onSearch={searchManufacturers}
					identifierKey="code"
					name={name}
					onCopy={handleCopy}
					handlePasteSelection={handleInsert}
					placeholder="Select manufacturer..."
					contentSource={(o) => o.name}
					isClearable={true}
					onOptionSelected={props.onSelectedManufacturerChange}
					selectedOption={props.selectedManufacturer}
					initialValue={props.initialValue}
					previewSource={(o) => o.name}
				/>
			)}
		</FormField>
	);
};

export default ManufacturerPicker;
