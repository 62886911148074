import { ENVIRONMENT_SHARED } from 'environment';
import {
	Boxes,
	Building,
	FileDigit,
	Folder,
	Map,
	Package,
	Phone,
	Plane,
	Scroll,
	Table,
	Table2,
	Upload,
} from 'lucide-react';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'ui/components/Footer';
import Header from 'ui/components/Header';
import { NavigationRootItem } from 'ui/components/Navigation/Navigation';
import Toaster from 'ui/components/Toaster';
import TransitionSpinner from 'ui/components/TransitionSpinner';
import { catchSilently } from 'utils/helpers/catchHandlers';
import {
	performLoginRedirect,
	performLogoutRedirect,
} from 'utils/helpers/msal';
import useRootLoader from 'utils/hooks/useRootLoader';
import useUpdateNotifications from 'utils/hooks/useUpdateNotifications';
import { LoaderData } from 'utils/types/loaderData';
import PortalUsageTrackingAPI from './api/PortalUsageTrackingAPI';
import { LOGIN_REQUEST, msalInstance } from './auth/authConfig';
import { rootLoader } from './main';
import './styles/base.scss';
import './styles/layout.scss';

const getNavigationItems = (
	rootLoaderData: LoaderData<typeof rootLoader>
): NavigationRootItem<'cargois-user'>[] => {
	// Hide report hub dropdown if only one report type is available
	const reportHubDropdownVisible = rootLoaderData
		? [
				rootLoaderData.userProfile.reportCounts.totalStatic,
				rootLoaderData.userProfile.reportCounts.totalBip,
				rootLoaderData.userProfile.reportCounts.totalWebTool,
				rootLoaderData.userProfile.reportCounts.totalAdhoc,
			].filter((count) => count > 0).length > 1
		: false;

	const reportHubVisible = rootLoaderData
		? Object.values(rootLoaderData.userProfile.reportCounts).some(
				(count) => count > 0
			)
		: false;

	return [
		{
			name: 'Home',
			path: '/',
		},
		{
			name: 'Web Tool',
			path: '/webtool',
			isVisible: !!rootLoaderData && rootLoaderData.enableWebTool,
			sections: [
				{
					items: [
						{
							name: 'Web Tool',
							path: '/webtool',
							icon: Table2,
						},
						{
							name: 'Saved & Scheduled Reports',
							path: '/webtool?action=browse',
							icon: Folder,
						},
						{
							name: 'Custom Groupings',
							path: '/webtool/groups',
							icon: Boxes,
						},
					],
				},
			],
		},
		{
			name: 'CDD Contributor',
			path: '/cdd-contributor',
			isVisible: !!rootLoaderData && rootLoaderData.enableCddContributor,
			sections: [
				{
					items: [
						{
							name: 'CDD Submissions',
							path: '/cdd-contributor',
							icon: Table,
						},
						{
							name: 'Add CDD Submission',
							path: '/cdd-contributor/upload',
							icon: Upload,
						},
					],
				},
			],
		},
		{
			name: 'NDD Contributor',
			path: '/ndd-contributor',
			isVisible: !!rootLoaderData && rootLoaderData.enableNddContributor,
			sections: [
				{
					items: [
						{
							name: 'NDD Submissions',
							path: '/ndd-contributor',
							icon: Table,
						},
						{
							name: 'Add NDD Submission',
							path: '/ndd-contributor/upload',
							icon: Upload,
						},
					],
				},
			],
		},
		{
			name: 'Report Hub',
			path: 'report-hub',
			isVisible: reportHubVisible,
			sections: [
				{
					items: [
						{
							name: 'Static Reports',
							path: '/report-hub/static-reports',
							icon: FileDigit,
							isVisible: rootLoaderData
								? rootLoaderData.userProfile.reportCounts.totalStatic > 0 &&
									reportHubDropdownVisible
								: false,
						},
						{
							name: 'BIP Exports',
							path: '/report-hub/bip-exports',
							icon: FileDigit,
							isVisible: rootLoaderData
								? rootLoaderData.userProfile.reportCounts.totalBip > 0 &&
									reportHubDropdownVisible
								: false,
						},
						{
							name: 'Adhoc Reports',
							path: '/report-hub/adhoc-reports',
							icon: FileDigit,
							isVisible: rootLoaderData
								? rootLoaderData.userProfile.reportCounts.totalAdhoc > 0 &&
									reportHubDropdownVisible
								: false,
						},
						{
							name: 'Web Tool Reports',
							path: '/report-hub/webtool-reports',
							icon: FileDigit,
							isVisible: rootLoaderData
								? rootLoaderData.userProfile.reportCounts.totalWebTool > 0 &&
									reportHubDropdownVisible
								: false,
						},
						{
							name: 'Scheduled Reports',
							path: '/report-hub/scheduled-reports',
							icon: FileDigit,
							isVisible: rootLoaderData
								? rootLoaderData.userProfile.reportCounts.totalWebTool > 0 &&
									reportHubDropdownVisible
								: false,
						},
					],
				},
			],
		},
		{
			name: 'Reference data',
			path: '/reference-data/airlines',
			sections: [
				{
					items: [
						{
							name: 'Airlines',
							path: '/reference-data/airlines',
							icon: Plane,
						},
						{
							name: 'Airports',
							path: '/reference-data/airports',
							icon: Building,
						},
						{
							name: 'Regions',
							path: '/reference-data/regions',
							icon: Map,
						},
						{
							name: 'Freight Forwarders',
							path: '/reference-data/freight-forwarders',
							icon: Package,
						},
					],
				},
			],
		},
		{
			name: 'Publications',
			path: '/publications',
		},
		{
			name: 'Support',
			sections: [
				{
					items: [
						{
							name: 'Contact Us',
							description: 'Get in touch with our support team',
							icon: Phone,
							path: ENVIRONMENT_SHARED.contactUsUrl,
							isExternal: true,
							onClick: () => {
								PortalUsageTrackingAPI.sendUsageTrackingExternalUrlRequest(
									ENVIRONMENT_SHARED.contactUsUrl,
									'Contact Us'
								).catch(catchSilently);
							},
						},
						{
							name: 'Knowledge Hub',
							description: 'Find answers to your questions',
							icon: Scroll,
							path: ENVIRONMENT_SHARED.knowledgeHubUrl,
							isExternal: true,
							onClick: () => {
								PortalUsageTrackingAPI.sendUsageTrackingExternalUrlRequest(
									ENVIRONMENT_SHARED.knowledgeHubUrl,
									'Knowledge Hub'
								).catch(catchSilently);
							},
						},
					],
				},
			],
		},
	];
};

function App() {
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;

	const navigationItems = useMemo(
		() => getNavigationItems(rootLoaderData),
		[rootLoaderData]
	);

	useUpdateNotifications();

	return (
		<div className="page-wrapper">
			<Header
				navigationItems={navigationItems}
				activeRole="cargois-user"
				onLogin={() => performLoginRedirect(msalInstance, LOGIN_REQUEST)}
				onLogout={() => performLogoutRedirect(msalInstance)}
				activeAccount={msalInstance.getActiveAccount() ?? undefined}
			/>
			<main>
				<Outlet />
			</main>
			<Footer isAuthenticated={msalInstance.getAllAccounts().length > 0} />
			<Toaster />
			<TransitionSpinner />
		</div>
	);
}

export default App;
