import {
	ColumnDef,
	SortingState,
	createColumnHelper,
} from '@tanstack/react-table';
import { ReactNode, memo, useCallback, useMemo, useRef } from 'react';
import Alert from 'ui/components/Alert/Alert';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import NumberFragment from 'ui/components/NumberFragment/NumberFragment';
import Table from 'ui/components/Table/Table';
import { TableSortingStrategy } from 'ui/components/Table/tableSorting';
import {
	ReportColumn,
	ReportColumnGroup,
	ReportDataFormat,
} from 'utils/api/WebToolAPI';
import { catchWithMessage } from 'utils/helpers/catchHandlers';
import { useWorksheetContext } from './WorksheetContext';

export enum WorksheetPreviewStatus {
	Hidden,
	Error,
	ReportRunning,
	ReportCompleted,
	ReportMovedToBackground,
	LoadingPreview,
}

type WorksheetPreviewProps = {};

const WorksheetPreview = ({}: WorksheetPreviewProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const columnHelper = createColumnHelper<any>();
	const {
		previewData,
		status,
		queryStatus,
		previewWorksheet,
		runWorksheet,
		lastExportFormat,
		state,
		hasSortingChangedSinceLastRun,
		hasLayoutCompositionChangedSinceLastRun,
		setDeepState,
		setLastRunState,
		isMoveToBackgroundAvailable,
		moveToBackground,
	} = useWorksheetContext();

	const rightAlignedColumns: Array<ReportDataFormat> = [
		'number',
		'weight',
		'volume',
		'currency',
		'difference',
		'ratio',
		'distance',
		'percentage',
		'exchange-rate',
	];

	const createColumnFromReportColumn = (reportColumn: ReportColumn) => {
		return columnHelper.accessor(reportColumn.id, {
			header: reportColumn.name,
			footer:
				reportColumn.totalValue !== undefined &&
				reportColumn.totalValue !== null
					? () => (
							<DimensionDataFragment
								dimension={reportColumn}
								value={reportColumn.totalValue}
							/>
						)
					: undefined,
			cell: (info) => (
				<DimensionDataFragment
					dimension={reportColumn}
					value={info.getValue()}
				/>
			),
			enableSorting: true,
			meta: {
				shrink: true,
				contentAlignment: rightAlignedColumns.includes(reportColumn.format)
					? 'right'
					: 'left',
			},
		});
	};

	const createColumnFromReportColumnGroup = (
		reportColumnGroup: ReportColumnGroup
	): ColumnDef<any, any> => {
		return columnHelper.group({
			id: reportColumnGroup.name,
			header: reportColumnGroup.name,
			meta: {
				headerAlignment: reportColumnGroup.headerAlignment,
			},
			columns: reportColumnGroup.items.map((columnOrGroup) =>
				createColumnFromReportColumnOrGroup(columnOrGroup)
			),
		});
	};

	const createColumnFromReportColumnOrGroup = (
		columnOrGroup: ReportColumnGroup | ReportColumn
	) => {
		if ('items' in columnOrGroup) {
			return createColumnFromReportColumnGroup(columnOrGroup);
		} else {
			return createColumnFromReportColumn(columnOrGroup);
		}
	};

	const columns = useMemo(() => {
		return previewData?.columns.map(createColumnFromReportColumnOrGroup);
	}, [previewData?.columns]);

	const handleStaleReload = useCallback(() => {
		if (hasLayoutCompositionChangedSinceLastRun) {
			runWorksheet('screen').catch(catchWithMessage('Failed to run report'));
		} else if (hasSortingChangedSinceLastRun) {
			if (!queryStatus) return;

			setLastRunState((prevState) => ({
				...prevState,
				layout: {
					...prevState.layout,
					sorting: structuredClone(state.layout.sorting),
				},
			}));

			previewWorksheet(queryStatus.id, state.layout.sorting).catch(
				catchWithMessage('Failed to update preview')
			);
		}
	}, [
		queryStatus,
		previewWorksheet,
		state.layout.sorting,
		hasLayoutCompositionChangedSinceLastRun,
		hasSortingChangedSinceLastRun,
		runWorksheet,
		setLastRunState,
	]);

	const sortingStrategy = useMemo(
		(): TableSortingStrategy => ({
			onSort(columnName, sortOrder, multiSort) {
				if (!queryStatus) return;

				let newSortingState: SortingState | null = null;

				if (sortOrder === null) {
					// The sort order has been removed
					newSortingState = multiSort
						? state.layout.sorting.filter((sort) => sort.id !== columnName)
						: [];
				} else if (
					multiSort &&
					state.layout.sorting.some((sort) => sort.id === columnName)
				) {
					// It is a multi-sort, and the sorting item already exists (update in place)
					newSortingState = state.layout.sorting.map((sort) => ({
						...sort,
						desc: sort.id === columnName ? sortOrder === 'desc' : sort.desc,
					}));
				} else if (multiSort) {
					// It is a multi-sort, and the sorting item does not exist (add it)
					newSortingState = [
						...state.layout.sorting,
						{
							id: columnName,
							desc: sortOrder === 'desc',
						},
					];
				} else {
					newSortingState = [
						{
							id: columnName,
							desc: sortOrder === 'desc',
						},
					];
				}

				setDeepState('layout.sorting', newSortingState);
			},
			getSortingState(columns) {
				return state.layout.sorting;
			},
		}),
		[queryStatus, state.layout.sorting, previewWorksheet]
	);

	if (status === WorksheetPreviewStatus.Hidden) {
		return null;
	}

	let content: ReactNode;

	if (status === WorksheetPreviewStatus.ReportMovedToBackground) {
		content = (
			<Alert title="Report moved to background" intent="info">
				The report has been moved to the background. We will notify you via
				email when it is ready.
			</Alert>
		);
	} else if (previewData?.previewTimeout) {
		content = (
			<Alert title={'Preview gathering timed out'} intent="warning">
				The result is currently too large to preview, export the data instead.
			</Alert>
		);
	} else if (previewData?.errorMessage) {
		content = (
			<Alert title="Unexpected error" intent="error">
				<p>{previewData.errorMessage}</p>
			</Alert>
		);
	} else if (status === WorksheetPreviewStatus.Error) {
		content = (
			<Alert title="Problem with the report" intent="error">
				<p>
					{(queryStatus?.executionStatus === 'FAILED'
						? queryStatus.errorMessage
						: previewData?.errorMessage) ||
						'An unexpected error occurred while running the report'}
				</p>
			</Alert>
		);
	} else if (
		status === WorksheetPreviewStatus.ReportCompleted &&
		lastExportFormat &&
		lastExportFormat !== 'screen'
	) {
		content = (
			<Alert title="Export completed" intent="info">
				The report has been exported and the report file downloaded.
			</Alert>
		);
	} else if (
		status === WorksheetPreviewStatus.ReportCompleted &&
		lastExportFormat === 'screen' &&
		!previewData
	) {
		content = (
			<Alert title="Preview expired" intent="info">
				The preview has expired. Please run the report again.
			</Alert>
		);
	} else {
		content = (
			<div className="worksheet__preview-wrapper">
				{previewData &&
					previewData.reportMessages.length > 0 &&
					previewData.reportMessages.map((message, messageIdx) => (
						<Alert key={messageIdx} title={message} intent="info" />
					))}
				<Table
					columns={columns ?? []}
					data={previewData?.items ?? []}
					identifierKey="id"
					className="table--grouped table--min"
					showTotalLabels
					sortingStrategy={sortingStrategy}
					loadingActions={
						status === WorksheetPreviewStatus.ReportRunning &&
						isMoveToBackgroundAvailable
							? [
									{
										text: 'Move to background',
										onClick: moveToBackground,
									},
								]
							: undefined
					}
					isLoading={
						status === WorksheetPreviewStatus.ReportRunning ||
						status === WorksheetPreviewStatus.LoadingPreview
					}
					loadingText={
						status === WorksheetPreviewStatus.LoadingPreview
							? 'Loading preview...'
							: 'Running report...'
					}
					loadingHint={
						isMoveToBackgroundAvailable
							? 'This report is taking a while.\nYou can move it to the background and we will send you an email when it is ready.'
							: undefined
					}
					hasWarning={
						hasLayoutCompositionChangedSinceLastRun ||
						hasSortingChangedSinceLastRun
					}
					warningText={
						hasLayoutCompositionChangedSinceLastRun
							? 'The report composition has changed. Re-run the report to update the preview.'
							: `The sorting has been updated. You can select more by holding the 'Shift' key to sort by multiple columns.`
					}
					warningActions={[
						{
							text: hasLayoutCompositionChangedSinceLastRun
								? 'Rerun report'
								: 'Refresh data sorting',
							onClick: handleStaleReload,
						},
						// {
						// 	text: 'Reset',
						// 	onClick: () => {
						// 		setDeepState('layout.sorting', lastRunState.layout.sorting);
						// 	},
						// },
					]}
				/>
				{previewData?.displayReportParameters && (
					<Card
						label="Report Details & Parameters"
						className="worksheet__report-details"
					>
						<Flex direction="column" gap={8}>
							<div className="worksheet__report-generation">
								<b>Report Generation:</b>{' '}
								<DateFragment
									date={previewData.reportGeneratedAt}
									includeTime
								/>{' '}
								by {previewData.reportGeneratedBy}
							</div>
							<div>
								<b>Report Parameters:</b>
								<ul>
									{previewData.reportParameters.map((param) => (
										<li key={param.name}>
											<i>{param.name}</i>: {param.value.join(', ')}
										</li>
									))}
								</ul>
							</div>
						</Flex>
					</Card>
				)}
			</div>
		);
	}

	return (
		<Flex
			className="worksheet__preview"
			direction="column"
			alignItems="center"
			justifyContent="start"
			gap={20}
			ref={ref}
		>
			{content}
		</Flex>
	);
};

const DimensionDataFragment = memo(
	({
		dimension,
		value,
	}: {
		dimension: { format: ReportDataFormat };
		value: unknown;
	}) => {
		const emptyText = '-';
		switch (dimension.format) {
			case 'date':
				return (
					<DateFragment
						date={value as Date}
						emptyText={emptyText}
						timezone="utc"
					/>
				);
			case 'month':
				return (
					<DateFragment
						date={value as Date}
						includeDay={false}
						emptyText={emptyText}
						timezone="utc"
					/>
				);
			case 'number':
				return <NumberFragment value={value as number} />;
			case 'weight':
			case 'volume':
				return <NumberFragment value={value as number} decimalPlaces={1} />;
			case 'currency':
			case 'difference':
			case 'ratio':
			case 'distance':
				return <NumberFragment value={value as number} decimalPlaces={2} />;
			case 'percentage':
				return (
					<NumberFragment
						value={value as number}
						decimalPlaces={2}
						suffix="%"
					/>
				);
			case 'exchange-rate':
				return <NumberFragment value={value as number} decimalPlaces={5} />;
			case 'text':
				return <>{value ?? emptyText}</>;
			default:
				return <>{value?.toString() ?? emptyText}</>;
		}
	},
	(prevProps, nextProps) => {
		return (
			prevProps.dimension.format === nextProps.dimension.format &&
			prevProps.value === nextProps.value
		);
	}
);

export default WorksheetPreview;
