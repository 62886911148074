import { useMemo } from 'react';
import Alert from 'ui/components/Alert/Alert';
import Flex from 'ui/components/Flex/Flex';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { ValidatedFormProps } from 'ui/components/ValidatedForm/ValidatedForm';
import { WebtoolSubscription } from 'utils/api/WebToolAPI';
import z from 'zod';
import { DropdownField } from '../../../../admin/src/forms/DropdownField';

type CreateWorksheetFormProps = {
	subscriptions: WebtoolSubscription[];
} & Omit<ValidatedFormProps, 'action' | 'method'>;

const createWebtoolWorksheetSchema = z.object({
	name: z.string().min(1, 'Name is required'),
	subscriptionId: z.string().min(1, 'Subscription is required'),
});

const CreateWorksheetForm = ({
	subscriptions,
	...formProps
}: CreateWorksheetFormProps) => {
	const ownSubscriptions = useMemo(() => {
		return subscriptions.filter(
			(subscription) => !subscription.supportSubscription
		);
	}, [subscriptions]);

	const supportSubscriptionGroups = useMemo(() => {
		const subscriptionsByCustomer = subscriptions
			.filter((subscription) => subscription.supportSubscription)
			.reduce<Record<string, WebtoolSubscription[]>>((acc, cur) => {
				if (!acc[cur.customer.id]) {
					acc[cur.customer.id] = [];
				}

				acc[cur.customer.id].push(cur);

				return acc;
			}, {});

		return Object.values(subscriptionsByCustomer).map((subscriptions) => ({
			label: subscriptions[0].customer.name,
			options: subscriptions,
		}));
	}, [subscriptions]);

	if (subscriptions.length === 0) {
		return (
			<Alert intent="warning" title="No subscriptions">
				You do not have any subscriptions.
			</Alert>
		);
	}

	return (
		<ValidatedForm
			action="/webtool"
			method="post"
			validator={createWebtoolWorksheetSchema}
			{...formProps}
		>
			<Flex direction="column" gap={16}>
				<TextField
					name="name"
					label="Name"
					placeholder="Enter the report name"
					autoFocus
				/>

				{subscriptions.length > 1 ? (
					<DropdownField
						label="Subscription"
						name="subscriptionId"
						options={
							supportSubscriptionGroups.length > 0
								? [
										{
											label: 'My subscriptions',
											options: ownSubscriptions,
										},
										...supportSubscriptionGroups,
									]
								: ownSubscriptions
						}
						contentSource={(subscription) => subscription.name}
						identifierKey="id"
						isGrouped={supportSubscriptionGroups.length > 0}
					/>
				) : (
					<input
						type="hidden"
						name="subscriptionId"
						value={subscriptions[0].id}
					/>
				)}
			</Flex>
		</ValidatedForm>
	);
};

export default CreateWorksheetForm;
